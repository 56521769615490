import { Injectable } from '@angular/core';
import { AndroidBiometryStrength, BiometricAuth, BiometryError, BiometryErrorType, BiometryType } from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { localStorageSafe } from '../functions';

@Injectable({
    providedIn: 'root'
})
export class BiometricService {
    public constructor(
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
    ) { }

    public async authenticateWithBiometric(withLoginRequest = true): Promise<void> {
        const biometricUUID = localStorage.getItem('biometric_uuid');

        if (Capacitor.isNativePlatform() && biometricUUID) {
            const info = await BiometricAuth.checkBiometry();
            if (info.isAvailable) {
                console.log('Biometry is available', info);
                void this._authenticate(withLoginRequest);
            } else {
                console.log('Biometry is not available', info);
            }
        }
    }

    public async getAvailableTypeString(): Promise<string> {
        const info = await BiometricAuth.checkBiometry();
        if (info.biometryType === BiometryType.faceId) {
            return 'Face ID';
        }
        if (info.biometryType === BiometryType.touchId) {
            return 'Touch ID';
        }
        if (info.biometryType === BiometryType.fingerprintAuthentication) {
            return this._translateService.instant('public_pages.biometric_login_fingerprint');
        }
        if (info.biometryType === BiometryType.faceAuthentication) {
            return this._translateService.instant('public_pages.biometric_login_face');
        }
        return 'None';
    }

    private async _authenticate(withLoginRequest = true): Promise<void> {
        this._authService.startLoginLoader('biometric');
        try {
            await BiometricAuth.authenticate({
                reason: 'Please authenticate',
                cancelTitle: 'Cancel',
                allowDeviceCredential: true,
                iosFallbackTitle: 'Use passcode',
                androidTitle: 'Biometric login',
                androidSubtitle: 'Log in using biometric authentication',
                androidConfirmationRequired: false,
                androidBiometryStrength: AndroidBiometryStrength.weak,
            });
            console.log('Authenticated successfully');
            localStorageSafe.setItem('biometric_active', 'true');

            if (withLoginRequest) {
                this._authService.login(null, null, localStorage.getItem('biometric_uuid'));
            } else {
                this._authService.stopLoginLoader('biometric');
            }

        } catch (error) {
            // error is always an instance of BiometryError.
            this._authService.stopLoginLoader('biometric');
            if (error instanceof BiometryError) {
                console.log(error);
                if (error.code !== BiometryErrorType.userCancel) {
                    // Display the error.
                }
            }
        }
    }
}
