<!--<editor-fold desc="Modals">-->
<ng-template
    #filterFormOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmit()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'employees_datetimes.filter_section_time' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees_datetimes.filter_date_from' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="true"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="from"
                                    mwlFlatpickr
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees_datetimes.filter_date_to' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="true"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="to"
                                    mwlFlatpickr
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees_datetimes.filter_section_type' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees_datetimes.filter_type' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'employee_datetime_type_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="types$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="types"
                                >
                                    <ng-template ng-multi-label-tmp>
                                      <span
                                          class="ng-placeholder"
                                          style="display: block;"
                                      >
                                        <span>{{ 'employees_datetimes.filter_types_count' | translate }}</span>
                                        <span> (</span>
                                        <span>{{ getCount('types') }}</span>
                                        <span>)</span>
                                      </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees_datetimes.filter_state' | translate }}</label>

                                <ng-select
                                    *ngIf="!toApprove"
                                    [bindLabel]="'label'"
                                    [bindValue]="'value'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="states$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="states"
                                >
                                    <ng-template ng-multi-label-tmp>
                                      <span
                                          class="ng-placeholder"
                                          style="display: block;"
                                      >
                                        <span>{{ 'employees_datetimes.filter_states_count' | translate }}</span>
                                        <span> (</span>
                                        <span>{{ getCount('states') }}</span>
                                        <span>)</span>
                                      </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="toApprove">
                                    <input [value]="('employees_datetimes.state_NEW' | translate)" class="form-control" disabled readonly type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees_datetimes.filter_section_employee' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees_datetimes.filter_employee' | translate }}</label>

                                <ng-select
                                    *ngIf="!employee"
                                    [bindValue]="'employee_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employees$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="employeesIDs"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees_datetimes.filter_employees_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('employeesIDs') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.fullname }}</span>
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="employee">
                                    <input [value]="employee.fullname" class="form-control" disabled readonly type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'employees_datetimes.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #content id="approveDenySimple" let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <p
                *ngIf="selectedEmployeeDatetimeActionType === 'approved'"
                class="modal-title font-16 mb-0 text-dark"
            >{{ 'employees_datetimes.action_approve_title' | translate }}</p>

            <p
                *ngIf="selectedEmployeeDatetimeActionType === 'denied'"
                class="modal-title font-16 mb-0 text-dark"
            >{{ 'employees_datetimes.action_deny_title' | translate }}</p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
                type="button"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        ><i class="mdi mdi-close"></i></span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form #f="ngForm">
                                <div
                                    *ngIf="selectedEmployeeDatetimeActionType === 'denied'"
                                    class="row"
                                >
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.type' | translate }}: </span>
                                                <span>{{ selectedEmployeeDatetime?.type_icon }}</span>
                                                <span class="ml-2 text-dark">{{ selectedEmployeeDatetime?.type_name }}</span>
                                            </p>
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.deny_datetimes' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.employee?.fullname }}</span>
                                            </p>
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.from' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.from | date:'dd.MM.yyyy HH:mm' }}</span>
                                            </p>
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.to' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.to | date:'dd.MM.yyyy HH:mm' }}</span>
                                            </p>
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.hours_duration' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.hours_duration }} {{ 'employees_datetimes.hours' | translate }}</span>
                                            </p>
                                        </div>

                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_datetimes.reason' | translate }}</label>

                                            <textarea
                                                class="form-control"
                                                name="reason"
                                                ngModel
                                            ></textarea>
                                            <!-- <div *ngIf="submitted && f.report.errors">
                                                                      <p *ngIf="f.report.errors.required" class="text-danger">
                                                                          {{ 'angular_validation.field' | translate }} {{ 'employees_datetimes.report' | translate }}
                                                                          {{ 'angular_validation.required' | translate }}
                                                                      </p>
                                                                  </div> -->
                                        </div>
                                    </div>
                                </div>

                                <div
                                    *ngIf="selectedEmployeeDatetimeActionType === 'approved'"
                                    class="row"
                                >
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.type' | translate }}: </span>
                                                <span>{{ selectedEmployeeDatetime?.type_icon }}</span>
                                                <span class="ml-2 text-dark">{{ selectedEmployeeDatetime?.type_name }}</span>
                                            </p>

                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.approve_datetimes' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.employee?.fullname }}</span>
                                            </p>

                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.from' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.from | date:'dd.MM.yyyy HH:mm' }}</span>
                                            </p>

                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.to' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.to | date:'dd.MM.yyyy HH:mm' }}</span>
                                            </p>
                                            <p>
                                                <span class="text-muted">{{ 'employees_datetimes.hours_duration' | translate }}: </span>
                                                <span class="text-dark">{{ selectedEmployeeDatetime?.hours_duration }} {{ 'employees_datetimes.hours' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12 text-left">
                                        <button
                                            (click)="modal.close(f.value)"
                                            *ngIf="selectedEmployeeDatetimeActionType === 'denied'"
                                            class="btn btn-success"
                                            ngbAutofocus
                                            type="submit"
                                        >{{ 'employees_datetimes.action_deny' | translate }}</button>

                                        <button
                                            (click)="modal.close(f.value)"
                                            *ngIf="selectedEmployeeDatetimeActionType === 'approved'"
                                            class="btn btn-success"
                                            ngbAutofocus
                                            type="submit"
                                        >{{ 'employees_datetimes.action_approve' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #bulkDelete
    id="bulkDelete"
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark"
            >{{ 'employees_datetimes.action_multi_delete_title' | translate }}</p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
                type="button"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        ><i class="mdi mdi-close"></i></span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col mb-2">
                                    {{ 'employees_datetimes.really_delete_multiple' | translate }}
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-12 text-right">
                                    <button
                                        (click)="modal.close()"
                                        class="btn btn-success"
                                        type="submit"
                                    >{{ 'global.action_delete' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #approveDenyMulti
    id="approveDenyMulti"
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p
                *ngIf="multiAction === 'approved'"
                class="modal-title font-16 mb-0 text-dark"
            >{{ 'employees_datetimes.action_approve_title' | translate }}</p>

            <p
                *ngIf="multiAction === 'denied'"
                class="modal-title font-16 mb-0 text-dark"
            >{{ 'employees_datetimes.action_deny_title' | translate }}</p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
                type="button"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        ><i class="mdi mdi-close"></i></span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form #f="ngForm">
                                <div *ngIf="multiAction === 'approved'" class="row">
                                    <div class="col mb-2">
                                        {{ 'employees_datetimes.really_approve_multiple' | translate }}
                                    </div>
                                </div>
                                <div *ngIf="multiAction === 'denied'">
                                    <div class="row">
                                        <div class="col mb-2">
                                            {{ 'employees_datetimes.really_deny_multiple' | translate }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="control-label">{{ 'employees_datetimes.reason' | translate }}</label>
                                                <textarea class="form-control" name="reason" ngModel></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12 text-right">
                                        <button
                                            (click)="modal.close(f.value)"
                                            *ngIf="multiAction === 'denied'"
                                            class="btn btn-success"
                                            type="submit"
                                        >{{ 'employees_datetimes.action_deny' | translate }}</button>

                                        <button
                                            (click)="modal.close(f.value)"
                                            *ngIf="multiAction === 'approved'"
                                            class="btn btn-success"
                                            type="submit"
                                        >{{ 'employees_datetimes.action_approve' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--</editor-fold>-->

<div *ngIf="!toApprove && !employee && !isDashboard" class="row">
    <div class="col-12">
        <div class="card mb-0">
            <div class="row mb-0">
                <div class="col-12">
                    <ul class="nav nav-tabs nav-justified nav-bordered">
                        <li class="nav-item">
                            <a
                                routerLink="{{isShift ? '/employee/attendance/shifts/chart' : '/employee/attendance/chart'}}"
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                            >
                                <i class="mdi mdi-24px mdi-calendar-month-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'employees_datetimes.page_title_index_chart' | translate }}</span>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="{{isShift ? '/employee/attendance/shifts/history' : '/employee/attendance/history'}}"
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                            >
                                <i class="mdi mdi-24px mdi-clock-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'employees_datetimes.page_title_index_history' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{'position-static': isStatic}" class="card h-100 shadow">
    <app-help *ngIf="isDashboard" name="dashboard.employee_attendance_to_approve"></app-help>
    <app-help *ngIf="!isDashboard" name="attendance.list"></app-help>

    <div *ngIf="!isDashboard" class="card-header py-3">
        <div class="row">
            <div class="col-6 text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span *ngIf="!title">{{ 'employees_datetimes.requests' | translate }}</span>
                    <span *ngIf="title">{{ title | translate }}</span>
                </p>
            </div>
            <div class="col-6 text-right">
                <button *ngIf="isEmployeeDetail" (click)="goToMonthOverview()" class="btn btn-primary btn-icon">
                    <i class="mdi mdi-sigma"></i>
                </button>
                <button
                    (click)="openFilterFormOptions()"
                    [formGroup]="filterForm"
                    class="btn btn-primary btn-icon ml-2"
                    type="button"
                >
                    <i class="mdi mdi-filter-outline"></i>
                </button>
                <button (click)="openEditModal()"
                        *ngIf="showCreateButton && (employee || authService.employee)"
                        [createButton]="'employees_datetimes.action_create' | translate"
                        class="ml-2"
                ></button>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <app-data-table
                        (searchChanged)="searchChanged($event)"
                        (sortChanged)="sortChanged($event)"
                        *ngIf="mode != 'compact'"
                        [colsConfig]="{width: colsConfig}"
                        [customNoDataMessage]="noDataMessage"
                        [disableInPlaceFiltering]="true"
                        [disableInPlaceSorting]="true"
                        [loading]="loading"
                        [rows]="rows"
                        [search]="search"
                        [showHeader]="showHeader"
                        [showSearch]="showHeader"
                        [sort]="sort"
                        [trackByFn]="trackByFn"
                    >
                        <ng-template #headerRow>
                            <th *ngIf="showBulkMode">
                                <div class="form-check">
                                    <input (click)="checkUncheckAll($event)" [(ngModel)]="checkedWholePage" aria-label="..." class="form-check-input position-static"
                                           id="blankCheckbox" type="checkbox" value="option1">
                                </div>
                            </th>
                            <th appDataTableHeaderCell="employee.fullname">
                                <span>{{ 'employees_datetimes.employee_full_name' | translate }}</span>
                            </th>

                            <th appDataTableHeaderCell="from">
                                <span>{{ 'employees_datetimes.from_to' | translate }}</span>
                            </th>

                            <th appDataTableHeaderCell="type.name">
                                <span>{{ 'employees_datetimes.type' | translate }}</span>
                            </th>

                            <th *ngIf="showProjects" appDataTableHeaderCell [disableSort]="true">
                                <span>{{ 'employees_datetimes.project' | translate }}</span>
                            </th>

                            <th
                                *ngIf="toApprove || employee"
                                [disableSort]="true"
                                appDataTableHeaderCell
                            >
                                <span>{{ 'employees_datetimes.state' | translate }}</span>
                            </th>

                            <th
                                [disableSort]="true"
                                appDataTableHeaderCell
                                class="text-right"
                            >
                                <span>{{ 'employees_datetimes.table_action' | translate }}</span>
                            </th>
                        </ng-template>

                        <ng-template
                            #dataRow
                            let-row
                        >
                            <td *ngIf="showBulkMode">
                                <div class="form-check">
                                    <input (click)="rowCheckBoxChecked($event, row.employee_datetime_ID)" [checked]="checkedEmployeeDatetimeIds.includes(row.employee_datetime_ID)" aria-label="..."
                                           class="form-check-input position-static" type="checkbox" value="1">
                                </div>
                            </td>
                            <td appDataTableDataCell
                                class="w-sm-15">
                                <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
                            </td>

                            <td appDataTableDataCell>
                                <div class="value-wrapper">
                                  <span class="value" [innerHTML]="row.from_to_label">
                                    <!--<span>{{ row.from | date:'dd.MM.yyyy HH:mm' }}</span>
                                    <br>
                                    <span>{{ row.to ? (row.to | date: 'dd.MM.yyyy HH:mm') : ('employees_datetimes.now' | translate) }}</span>-->
                                  </span>
                                </div>
                            </td>

                            <td appDataTableDataCell>
                                <div>
                                    <span class="mr-1">{{ row.type_icon }}</span>

                                    <span>{{ row.type.name }}</span>
                                    <span *ngIf="row.planned" class="text-truncate text-muted ml-1" style="font-size: 13px">({{ 'employees_datetimes.plan' | translate }}
                                        )</span>
                                </div>
                            </td>

                            <td *ngIf="showProjects" appDataTableDataCell>
                                <span>{{ row.employee_datetime_project_name }}</span>
                            </td>

                            <td
                                *ngIf="toApprove || employee"
                                appDataTableDataCell
                            >
                                <div>
                      <span
                          *ngIf="row.state === 'APPROVED'"
                          class="mr-1"
                      >✅</span>

                                    <span
                                        *ngIf="row.state === 'DENIED'"
                                        class="mr-1"
                                    >❌</span>

                                    <span>{{ 'employees_datetimes_states.type_' + row.state | translate }}</span>
                                    <span class="ml-1" *ngIf="row.state === 'NEW'" [ngbTooltip]="('employees_datetimes.waiting_to_approve_from' | translate) + row.owner_fullname"
                                          container="body"
                                          placement="top-left"><i class="mdi mdi-information text-primary"></i></span>
                                </div>
                            </td>

                            <td
                                appDataTableDataCell
                                class="text-right"
                            >
                                <div>
                                    <a
                                        [routerLink]="['/employee', row.employee?.employee_ID, 'attendance', 'detail', row.employee_datetime_ID]"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees.action_view' | translate }}"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </a>

                                    <button
                                        (click)="approveOrDeclineDateTime(row, 'approved', content)"
                                        *ngIf="row.state === 'NEW' && toApprove"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees_datetimes.action_approve' | translate }}"
                                    >
                                        <i class="mdi mdi-check mdi-24px text-muted"></i>
                                    </button>

                                    <button
                                        (click)="approveOrDeclineDateTime(row, 'denied', content)"
                                        *ngIf="row.state === 'NEW' && toApprove"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees_datetimes.action_deny' | translate }}"
                                    >
                                        <i class="mdi mdi-close mdi-24px text-muted"></i>
                                    </button>
                                </div>
                            </td>
                        </ng-template>
                    </app-data-table>
                    <app-data-table
                        (searchChanged)="searchChanged($event)"
                        (sortChanged)="sortChanged($event)"
                        *ngIf="mode == 'compact'"
                        [colsConfig]="{width: ['10%', '50%', '30%', '10%']}"
                        [customNoDataMessage]="noDataMessage"
                        [disableInPlaceFiltering]="true"
                        [disableInPlaceSorting]="true"
                        [loading]="loading"
                        [rows]="rows"
                        [search]="search"
                        [showHeader]="showHeader"
                        [showSearch]="showHeader"
                        [sort]="sort"
                        [trackByFn]="trackByFn"
                    >
                        <ng-template
                            #dataRow
                            let-row
                        >
                            <td>
                                <div class="form-check">
                                    <input (click)="rowCheckBoxChecked($event, row.employee_datetime_ID)" [checked]="checkedEmployeeDatetimeIds.includes(row.employee_datetime_ID)" aria-label="..."
                                           class="form-check-input position-static" type="checkbox" value="1">
                                </div>
                            </td>
                            <td
                                appDataTableDataCell
                                class="table-user"
                            >
                                <div class="media">
                                    <img *ngIf="row.employee.avatar"
                                         [src]="row.employee.avatar" alt="Avatar image"
                                         class="mr-3 rounded-circle" width="60">
                                    <div class="media-body my-auto">
                                        <h5 class="mt-0 mb-1 text-truncate" title="{{ row.employee.fullname }}">
                                            {{ row.employee.fullname }}
                                        </h5>
                                        <span class="font-13 text-truncate text-muted">{{ row.employee.position }}</span>
                                    </div>
                                </div>
                            </td>


                            <td>
                                <p class="mb-0 text-warning">
                                    <span>{{ row.type_icon }}</span><span>&nbsp;</span><span>{{ row.type.name }}</span>
                                </p>
                                <div>
                                    <span *ngIf="!row.set_on_shift">{{ row.from | date:'dd.MM HH:mm'}} - {{ row.to | date:'dd.MM HH:mm' }}</span>
                                    <span *ngIf="row.set_on_shift">{{ row.from | date:'dd.MM'}} {{ 'employees.absence_whole_day' | translate }}</span>
                                </div>
                            </td>

                            <td
                                appDataTableDataCell
                                class="text-right"
                            >
                                <div>
                                    <a
                                        [routerLink]="['/employee', row.employee.employee_ID, 'attendance', 'detail', row.employee_datetime_ID]"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees.action_view' | translate }}"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </a>
                                </div>
                            </td>
                        </ng-template>
                    </app-data-table>
                </div>
            </div>
        </div>

        <div class="row flex-column-reverse flex-sm-row">
            <div class="col-12 col-sm-2">
                <app-items-per-page-select
                    (itemsPerPageChanged)="itemsPerPageChanged($event)"
                    *ngIf="showHeader"
                    [itemsPerPage]="itemsPerPage"
                    [total]="itemsTotal"
                ></app-items-per-page-select>
            </div>

            <div class="col-12 col-sm-10">
                <app-pagination
                    (pageChanged)="pageChanged($event)"
                    [itemsPerPage]="itemsPerPage"
                    [page]="page"
                    [total]="itemsTotal"
                ></app-pagination>
            </div>
        </div>

        <div *ngIf="checkedEmployeeDatetimeIds.length" class="row mt-3 d-flex justify-content-between">
            <span class="ml-1">{{ 'employees_datetimes.selected_count' | translate }}: {{ checkedEmployeeDatetimeIds.length }}</span>
            <div *ngIf="document.location.href.includes('attendance/to-approve')">
                <button (click)="approveOrDeclineDateTimeChecked('approved', approveDenyMulti)" class="btn btn-success btn-sm mr-1"
                        title="{{ 'employees_datetimes.action_approve' | translate }}">
                    {{ 'employees_datetimes.action_approve' | translate }}
                </button>

                <button (click)="approveOrDeclineDateTimeChecked('denied', approveDenyMulti)" class="btn btn-danger btn-sm mr-1"
                        title="{{ 'employees_datetimes.action_deny' | translate }}">
                    {{ 'employees_datetimes.action_deny' | translate }}
                </button>
            </div>
            <div *ngIf="document.location.href.includes('attendance/history') || document.location.href.includes('attendance/shifts/history')">
                <button (click)="bulkDeleteDateTimeChecked(bulkDelete)" class="btn btn-danger btn-sm mr-1"
                        title="{{ 'global.action_delete' | translate }}">
                    {{ 'global.action_delete' | translate }}
                </button>
            </div>
        </div>
        <div *ngIf="isDashboard" class="text-right mt-2">
            <a
                class="text-muted"
                routerLink="/employee/attendance/to-approve"
            >
                <u>{{ 'employees_datetimes.all_data' | translate }}</u>
            </a>
        </div>
    </div>
</div>

