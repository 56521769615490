import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSummernoteModule } from 'ngx-summernote';
import { BenefitBonusModule } from '../benefit-bonus/benefit-bonus.module';
import { IntegrationsModule } from '../core/integrations.module';
import { DocumentModule } from '../document/document.module';
import { EntityUpdateRequestModule } from '../entity_update_request/entity_update_request.module';
import { GrowthModule } from '../growth/growth.module';
import { KpiModule } from '../kpi/kpi.module';
import { NoteModule } from '../note/note.module';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { PropertyModule } from '../property/property.module';
import { ScheduledTaskListModule } from '../scheduled-task/scheduled-task-list.module';
import { SharedModule } from '../shared/shared.module';
import { TagModule } from '../tag/tag.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule, UIRowLoaderModule, UIValueOrDashModule, } from '../ui';
import { AddressFormComponent } from './components/embedded-forms/address/address-form.component';
import { ContactPersonFormComponent } from './components/embedded-forms/contact-person/contact-person-form.component';
import { EmployeeFormComponent } from './components/embedded-forms/employee/employee-form.component';
import { PersonalDataFormComponent } from './components/embedded-forms/personal-data/personal-data-form.component';
import { PositionFormComponent } from './components/embedded-forms/position/position-form.component';
import { SalaryFormComponent } from './components/embedded-forms/salary/salary-form.component';
import { ChangeAvatarModalComponent } from './components/partials/change-avatar-modal/change-avatar-modal.component';
import { ChangeEmployeeActiveAccessModalComponent } from './components/partials/change-employee-active-access-modal/change-employee-active-access-modal.component';
import { ChangeEmployeeCompanyGroupModalComponent } from './components/partials/change-employee-company-group/change-employee-company-group-modal.component';
import { ChangeEmployeeInCompanyFromModalComponent } from './components/partials/change-employee-in-company-from-modal/change-employee-in-company-from-modal.component';
import { CreateBulkAttendanceModalComponent } from './components/partials/create-bulk-attendance-modal/create-bulk-attendance-modal.component';
import { CreateEditEmployeeAdjustementModalComponent } from './components/partials/create-edit-employee-adjustement-modal/create-edit-employee-adjustement-modal.component';
import { CreateEditEmployeeBonusModalComponent } from './components/partials/create-edit-employee-bonus-modal/create-edit-employee-bonus-modal.component';
import { CreateEditEmployeeContactPersonComponent } from './components/partials/create-edit-employee-contact-person/create-edit-employee-contact-person.component';
import { CreateEditEmployeeDatetimeModalComponent } from './components/partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';
import { CreateEditEmployeeDatetimePartModalComponent } from './components/partials/create-edit-employee-datetime-part-modal/create-edit-employee-datetime-part-modal.component';
import { CreateEditEmployeeReceivableModalComponent } from './components/partials/create-edit-employee-receivable-modal/create-edit-employee-receivable-modal.component';
import { CreateEmployeeTerminationModalComponent } from './components/partials/create-employee-termination-modal/create-employee-termination-modal.component';
import { EmployeeAddressesComponent } from './components/partials/employee-addresses/employee-addresses.component';
import { EmployeeAttendancePartsComponent } from './components/partials/employee-attendance-parts/employee-attendance-parts.component';
import { EmployeeBadgeComponent } from './components/partials/employee-badge/employee-badge.component';
import { EmployeeContactPersonsComponent } from './components/partials/employee-contact-persons/employee-contact-persons.component';
import { EmployeeFilesComponent } from './components/partials/employee-file/employee-files.component';
import { EmployeePayrollsComponent } from './components/partials/employee-payrolls/employee-payrolls.component';
import { EmployeePersonalDataComponent } from './components/partials/employee-personal-data/employee-personal-data-component';
import { EmployeePoliciesComponent } from './components/partials/employee-policies/employee-policies.component';
import { EmployeePositionsComponent } from './components/partials/employee-positions/employee-positions.component';
import { EmployeeSalariesComponent } from './components/partials/employee-salaries/employee-salaries.component';
import { CreateEditEmployeeActivityModalComponent } from './components/sections/create-edit-employee-activity-modal/create-edit-employee-activity-modal.component';
import { CreateEditEmployeeAddressModalComponent } from './components/sections/create-edit-employee-address-modal/create-edit-employee-address-modal.component';
import { CreateEditEmployeeModalComponent } from './components/sections/create-edit-employee-modal/create-edit-employee-modal.component';
import { CreateEditEmployeePersonalDataModalComponent } from './components/sections/create-edit-employee-personal-data-modal/create-edit-employee-personal-data-modal.component';
import { CreateEmployeeAccessModalComponent } from './components/sections/create-employee-access-modal/create-employee-access-modal.component';
import { CreateEmployeePositionModalComponent } from './components/sections/create-employee-position-modal/create-employee-position-modal.component';
import { CreateEmployeeSalaryComponent } from './components/sections/create-employee-salary/create-employee-salary.component';
import { EditEmployeeSupervisorComponent } from './components/sections/edit-employee-supervisor/edit-employee-supervisor.component';
import { EmployeeAbsenceListComponent } from './components/sections/employee-absence-list/employee-absence-list.component';
import { EmployeeAccessComponent } from './components/sections/employee-access/employee-access.component';
import { EmployeeActivityListComponent } from './components/sections/employee-activity-list/employee-activity-list.component';
import { EmployeeAnalysisComponent } from './components/sections/employee-analysis/employee-analysis.component';
import { EmployeeAttendanceChartComponent } from './components/sections/employee-attendance-chart/employee-attendance-chart.component';
import { EmployeeAttendanceDetailComponent } from './components/sections/employee-attendance-detail/employee-attendance-detail.component';
import { EmployeeAttendanceDocumentDetailComponent } from './components/sections/employee-attendance-document-detail/employee-attendance-document-detail.component';
import { EmployeeAttendanceDocumentEmployeeDetailComponent } from './components/sections/employee-attendance-document-employee-detail/employee-attendance-document-employee-detail.component';
import { EmployeeAttendanceDocumentsComponent } from './components/sections/employee-attendance-documents/employee-attendance-documents.component';
import { EmployeeAttendanceGrossSalaryCountComponent } from './components/sections/employee-attendance-gross-salary-count/employee-attendance-gross-salary-count.component';
import { EmployeeAttendanceHourGrossSalaryCountComponent } from './components/sections/employee-attendance-hour-gross-salary-count/employee-attendance-hour-gross-salary-count.component';
import { EmployeeAttendanceListComponent } from './components/sections/employee-attendance-list/employee-attendance-list.component';
import { EmployeeAttendanceNetSalaryCountComponent } from './components/sections/employee-attendance-net-salary-count/employee-attendance-net-salary-count.component';
import { EmployeeAttendanceNormalizeCheckComponent } from './components/sections/employee-attendance-normalize-check/employee-attendance-normalize-check.component';
import { EmployeeAttendanceRealNetSalaryCountComponent } from './components/sections/employee-attendance-real-net-salary-count/employee-attendance-real-net-salary-count.component';
import { EmployeeAttendanceStatsComponent } from './components/sections/employee-attendance-stats/employee-attendance-stats.component';
import { EmployeeAttendanceComponent } from './components/sections/employee-attendance/employee-attendance.component';
import { EmployeeBenefitBonusComponent } from './components/sections/employee-benefit-bonus/employee-benefit-bonus.component';
import { EmployeeContactInfoComponent } from './components/sections/employee-contact-info/employee-contact-info.component';
import { EmployeeContactListComponent } from './components/sections/employee-contact-list/employee-contact-list.component';
import { EmployeeDatetimesComponent } from './components/sections/employee-datetimes/employee-datetimes.component';
import { EmployeeDetailComponent } from './components/sections/employee-detail/employee-detail.component';
import { EmployeeDocumentsComponent } from './components/sections/employee-documents/employee-documents.component';
import { EmployeeKpisComponent } from './components/sections/employee-kpis/employee-kpis.component';
import { EmployeeListByTypeComponent } from './components/sections/employee-list-by-type/employee-list-by-type.component';
import { EmployeeListComponent } from './components/sections/employee-list/employee-list.component';
import { EmployeeOnboardingsComponent } from './components/sections/employee-onboardings/employee-onboardings.component';
import { EmployeeOverviewComponent } from './components/sections/employee-overview/employee-overview.component';
import { EmployeePositionsFilesComponent } from './components/sections/employee-positions-files/employee-positions-files.component';
import { EmployeePropertiesComponent } from './components/sections/employee-properties/employee-properties.component';
import { EmployeeSalariesPayrollsComponent } from './components/sections/employee-salaries-payrolls/employee-salaries-payrolls.component';
import { EmployeeScheduledTasksComponent } from './components/sections/employee-scheduled-tasks/employee-scheduled-tasks.component';
import { EmployeeSettingComponent } from './components/sections/employee-setting/employee-setting.component';
import { EmployeeShiftsChartComponent } from './components/sections/employee-shifts-chart/employee-shifts-chart.component';
import { ProtectUnprotectDatetimesComponent } from './components/sections/protect-unprotect-datetimes/protect-unprotect-datetimes.component';
import { EmployeeAnalysisAttendanceModule } from './employee-analysis-attendance.module';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeStructureTreeModule } from './employee-structure-tree.module';

@NgModule({
    declarations: [
        ChangeAvatarModalComponent,
        CreateEditEmployeeActivityModalComponent,
        CreateEditEmployeeAddressModalComponent,
        CreateEditEmployeeAdjustementModalComponent,
        CreateEditEmployeeBonusModalComponent,
        CreateEditEmployeeContactPersonComponent,
        CreateEditEmployeeDatetimeModalComponent,
        CreateEditEmployeeModalComponent,
        EditEmployeeSupervisorComponent,
        CreateEditEmployeeReceivableModalComponent,
        CreateEmployeeAccessModalComponent,
        CreateEmployeePositionModalComponent,
        CreateEmployeeSalaryComponent,
        CreateEmployeeTerminationModalComponent,
        CreateEditEmployeePersonalDataModalComponent,
        EmployeeAccessComponent,
        EmployeeActivityListComponent,
        EmployeeAddressesComponent,
        EmployeeAnalysisComponent,
        EmployeeAttendanceChartComponent,
        EmployeeShiftsChartComponent,
        EmployeeAttendanceComponent,
        EmployeeAttendanceDetailComponent,
        EmployeeAttendanceDocumentDetailComponent,
        EmployeeAttendanceDocumentsComponent,
        EmployeeAttendanceGrossSalaryCountComponent,
        EmployeeAttendanceHourGrossSalaryCountComponent,
        EmployeeAttendanceListComponent,
        EmployeeAttendanceNetSalaryCountComponent,
        EmployeeAttendanceNormalizeCheckComponent,
        EmployeeAttendancePartsComponent,
        EmployeeAttendanceRealNetSalaryCountComponent,
        EmployeeBadgeComponent,
        EmployeeBenefitBonusComponent,
        EmployeeContactInfoComponent,
        EmployeeContactPersonsComponent,
        EmployeeDatetimesComponent,
        EmployeeDetailComponent,
        EmployeeDocumentsComponent,
        EmployeeFilesComponent,
        EmployeeKpisComponent,
        EmployeeListByTypeComponent,
        EmployeeListComponent,
        EmployeeOnboardingsComponent,
        EmployeeOverviewComponent,
        EmployeePayrollsComponent,
        EmployeePoliciesComponent,
        EmployeePositionsComponent,
        EmployeePositionsFilesComponent,
        EmployeePropertiesComponent,
        EmployeeSalariesComponent,
        EmployeeSalariesPayrollsComponent,
        EmployeeScheduledTasksComponent,
        EmployeeSettingComponent,
        EmployeePersonalDataComponent,
        ContactPersonFormComponent,
        PositionFormComponent,
        AddressFormComponent,
        PersonalDataFormComponent,
        EmployeeFormComponent,
        ChangeEmployeeCompanyGroupModalComponent,
        SalaryFormComponent,
        EmployeeContactListComponent,
        ChangeEmployeeInCompanyFromModalComponent,
        CreateBulkAttendanceModalComponent,
        EmployeeAttendanceStatsComponent,
        EmployeeAbsenceListComponent,
        ChangeEmployeeActiveAccessModalComponent,
        EmployeeAttendanceDocumentEmployeeDetailComponent,
        ProtectUnprotectDatetimesComponent,
        CreateEditEmployeeDatetimePartModalComponent
    ],
    imports: [
        EmployeeAnalysisAttendanceModule,
        EmployeeRoutingModule,
        DocumentModule,
        EmployeeStructureTreeModule,
        IntegrationsModule,
        KpiModule,
        OnboardingModule,
        ScheduledTaskListModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIFileUploadModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIRowLoaderModule,
        UIValueOrDashModule,
        NoteModule,
        TagModule,
        EntityUpdateRequestModule,
        GrowthModule,
        BenefitBonusModule,
        NgxSkeletonLoaderModule,
        NgOptimizedImage,
        NgxSummernoteModule,
        PropertyModule
    ],
    exports: [
        EmployeeAccessComponent,
        EmployeeAttendanceListComponent,
        EmployeeBadgeComponent,
        ContactPersonFormComponent,
        PositionFormComponent,
        AddressFormComponent,
        PersonalDataFormComponent,
        EmployeeFormComponent,
        SalaryFormComponent,
        ChangeEmployeeCompanyGroupModalComponent,
        EmployeeAttendanceStatsComponent,
        EmployeeAbsenceListComponent
    ]
})
export class EmployeeModule {
}
