import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from 'src/app/setting/services/datetimes-types-store.service';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-edit-datetime-type',
    templateUrl: './create-edit-datetime-type.component.html',
    styleUrls: ['./create-edit-datetime-type.component.css']
})
export class CreateEditDatetimeTypeComponent implements OnInit {
    public datetimeType: EmployeeDatetimeType;
    public datetimeTypeEditForm: UntypedFormGroup;
    public submitted = false;
    public showShiftDuration = false;
    public showCustomFromTo = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.datetimeTypeEditForm.controls;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        private _dateTimesTypeStoreService: DatetimesTypesStoreService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService
    ) { }

    public ngOnInit(): void {
        this.datetimeTypeEditForm = this._fb.group({
            name: ['', Validators.required],
            abbreviation: [''],
            color: ['', Validators.required],
            timer: [false, Validators.required],
            can_plan: [false, Validators.required],
            is_shift: [false, Validators.required],
            is_visible: [false, Validators.required],
            is_approvable: [true, Validators.required],
            is_active: [true, Validators.required],
            worktime_classification: ['NEUTRAL', Validators.required],
            custom_from_to: this._fb.group({
                from: ['08:00'],
                to: ['16:30'],
            }),
            custom_shift_duration: [null],
        });

        if (this.datetimeType) {
            this.datetimeTypeEditForm.patchValue(this.datetimeType);

            if (this.datetimeType.custom_shift_duration) {
                this.showShiftDuration = true;
            }

            if (this.datetimeType.custom_from_to?.from) {
                this.showCustomFromTo = true;
            }

            if (!this.datetimeType.can_delete) {
                this.datetimeTypeEditForm.get('name').disable();
            }
        }
    }

    public deleteDatetimeType(): void {
        this._dateTimesTypeStoreService.removeBranch(this.datetimeType.employee_datetime_type_ID, true)
            .then(() => this.activeModal.close('close'));
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.datetimeTypeEditForm.valid) {
            return;
        }

        let result = null;

        if (!this.showShiftDuration) {
            this.datetimeTypeEditForm.patchValue({custom_shift_duration: null});
        }

        const value = this.datetimeTypeEditForm.value.custom_from_to;
        if (!this.showCustomFromTo || value.from === '' || value.to === '' || value.from === null || value.to === null) {
            this.datetimeTypeEditForm.patchValue({custom_from_to: {from: null, to: null}});
        }

        if (this.datetimeType) {
            result = this._dateTimesTypeStoreService.updateDatetimeType(
                this.datetimeType.employee_datetime_type_ID,
                this.datetimeTypeEditForm.getRawValue()
            );
        } else {
            result = this._dateTimesTypeStoreService.addDatetimeType(this.datetimeTypeEditForm.value);
        }

        result
            .then(() => this.activeModal.close('close'));
    }

    public setShowShiftDuration(): void {
        this.showShiftDuration = !this.showShiftDuration;
    }
}
