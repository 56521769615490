import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from 'src/app/setting/services/datetimes-types-store.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-bulk-attendance-modal',
    templateUrl: './create-bulk-attendance-modal.component.html',
    styleUrls: ['./create-bulk-attendance-modal.component.css']
})
export class CreateBulkAttendanceModalComponent implements OnInit {
    public form: UntypedFormGroup;
    public submitted = false;
    public canCreate = false;
    public isShift = false;
    public selectedData: { ID: string, employee_ID: number, employee_datetime_ID?: number, start: number, end: number }[];
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public allTypes: EmployeeDatetimeType[];

    public constructor(
        public fpHelper: FlatpickrHelper,
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _attendanceService: AttendanceService,
        private _authService: AuthenticationService,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.canCreate = this._authService.checkPermission('employeeDatetime.approveOrDeny');

        this.form = this._fb.group({
            records: [this.selectedData.map(x => ({employee_id: x.employee_ID, date_timestamp: x.start}))],
            employee_datetime_type_id: [null, Validators.required],
            set_on: ['SHIFT', Validators.required],
            note: [null],
            state: [this.canCreate ? 'APPROVED' : 'NEW', Validators.required],
        });

        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(map(datetimes => {
                let filtered = [];
                if (this.isShift) {
                    filtered = datetimes.filter(y => y.is_shift === this.isShift);
                    this.form.patchValue({employee_datetime_type_id: filtered[0]?.employee_datetime_type_ID});
                } else {
                    filtered = datetimes.filter(y => y.timer === true || y.is_approvable === true);
                    // if is not shift, set default to holiday
                    this.form.patchValue({employee_datetime_type_id: filtered.find(x => x.internal_type === 'HOLIDAY')?.employee_datetime_type_ID});
                }
                this.allTypes = filtered;
                return filtered;
            }));

        this.onSetOnChange();
    }

    public onSetOnChange(): void {
        if (this.form.value.set_on === 'TIME') {
            this.form.addControl('from', this._fb.control(null, Validators.required));
            this.form.addControl('to', this._fb.control(null, Validators.required));
            const selectedDatetimeType = this.allTypes.find(x => x.employee_datetime_type_ID === this.form.value.employee_datetime_type_id);
            this.onTypeChanged(selectedDatetimeType);
        } else {
            this.form.removeControl('from');
            this.form.removeControl('to');
        }
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const value = this.form.value;

        this._attendanceService.createBulkAttendance(value)
            .then(() => this.activeModal.close(),
                (() => { }));
    }

    public onTypeChanged(selectedDatetimeType: EmployeeDatetimeType): void {
        if (this.form.value.set_on === 'TIME' && selectedDatetimeType.custom_from_to?.from && selectedDatetimeType.custom_from_to?.to) {
            this.form.patchValue({
                from: selectedDatetimeType.custom_from_to?.from,
                to: selectedDatetimeType.custom_from_to?.to,
            });
        }
    }
}
