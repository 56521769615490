<form
  *ngIf="settings$ | async"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="settings.attendance.indicator"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'settings.page_title_view_indicator' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <label class="control-label mb-0">
                        <span>{{ 'settings.attendance_indicator' | translate }}</span>
                    </label>
                </div>

                <div class="col-6 text-right align-self-center">
                    <div>
                        <input
                            data-switch="success"
                            formControlName="company.attendance.highlight_daily_hour_work_fond"
                            id="highlight_daily_hour_work_fond"
                            name="highlight_daily_hour_work_fond"
                            type="checkbox"
                        >

                        <label for="highlight_daily_hour_work_fond">&nbsp;</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right">
                    <small class="text-muted">{{ 'settings.attendance_indicator_explanation' | translate }}</small>
                </div>
            </div>
            <div [(ngbCollapse)]="!form.value['company.attendance.highlight_daily_hour_work_fond']">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'settings.excluded_employees' | translate }}</span>
                            </label>

                            <ng-select
                                [items]="employees$ | async"
                                [multiple]="true"
                                bindLabel="fullname"
                                bindValue="employee_ID"
                                [closeOnSelect]="false"
                                class="custom"
                                formControlName="company.attendance.highlight_excluded_employees"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'settings.value' | translate }}</span>
                            </label>

                            <input type="number"
                                   class="form-control"
                                   [max]="f['company.attendance.highlight_daily_hour_work_fond_unit'].value === 'percent' ? 100 : 24"
                                   min="0"
                                   onlyNumber
                                   formControlName="company.attendance.highlight_daily_hour_work_fond_treshold">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="control-label">
                                <span style="height: 12px; display: block;"> </span>
                            </label>

                            <ng-select
                                [items]="fondTypes"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                [closeOnSelect]="true"
                                class="custom"
                                formControlName="company.attendance.highlight_daily_hour_work_fond_unit"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <small class="text-muted">{{ 'settings.unit_description' | translate }}</small>
                    </div>
                </div>
            </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
