import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { FlatPickrOutputOptions, } from 'angularx-flatpickr/lib/flatpickr.directive';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

@Injectable()
export class FlatpickrHelper {
    public mondays: string[] = [];

    constructor(
        private _translateService: TranslateService,
    ) {
        this.generateMondays();
    }

    public applyAltInputValue(flatpickr: FlatPickrOutputOptions, isDateTime: boolean): void {
        let euroDate = flatpickr.instance.altInput.value;
        let euroTime: string;

        if (isDateTime) {
            euroTime = euroDate.substring(11, 16);
            euroDate = euroDate.substring(0, 10);
        }

        const parts = euroDate.split('.');
        let isoDate = parts.reverse().join('-');

        if (isDateTime) {
            if (
                !isoDate &&
                !euroTime
            ) {
                isoDate = null;
            } else {
                isoDate = isoDate + ' ' + euroTime;
            }
        }

        flatpickr.instance.setDate(isoDate, true);
    }

    public changeSeparator(flatpickr: FlatPickrOutputOptions): void {
        if (flatpickr.instance.altInput.value.includes('do')) {
            flatpickr.instance.altInput.value = flatpickr.instance.altInput.value.replace('do', '→');
        }
    }

    public generateMondays() {
        const mondays = [];
        const today = new Date();
        const currentYear = today.getFullYear();

        // Najít první pondělí v roce `startYear` zpět
        const firstMonday = new Date(currentYear - 5, 0, 1);  // První den v roce
        const dayOfWeek = firstMonday.getDay();  // 0 (neděle) až 6 (sobota)

        // Pokud první den není pondělí, posuneme se na pondělí
        let addDays = 1;
        if (this._translateService.currentLang !== 'en') {
            addDays = 2;
        }
        if (dayOfWeek !== 1) {
            firstMonday.setDate(firstMonday.getDate() + (addDays - dayOfWeek));  // Přesun na pondělí
        }

        // Iterovat od prvního pondělí
        const currentDate = firstMonday;

        while (currentDate.getFullYear() <= today.getFullYear() + 5) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            mondays.push(formattedDate);

            // Přesun na další pondělí (plus 7 dní)
            currentDate.setDate(currentDate.getDate() + 7);
        }

        this.mondays = mondays;
    }

    public monthPickerOptions(): FlatpickrDefaultsInterface {
        return {
            plugins: [monthSelectPlugin({dateFormat: 'Y-m-d', altFormat: 'F Y'})],
            allowInput: true,  // Povolit ruční zadání
            altFormat: 'F Y',  // Formát pro ruční zadání
            altInput: true,     // Povolit ruční zadání
            mode: 'single',     // Pouze jeden měsíc
        };
    }
}

