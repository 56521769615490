<div *ngIf="checkPermission('companyEmployeePolicy') && checkPermission('employeePolicyAdjustement') && (availableWidgets$ | async)?.employee?.datetimes?.policy" class="row">
    <div class="col-12">
        <app-employee-policies
            (refetchAdjustments)="getAdjustments()"
            (refetchPolicy)="getPolicy()"
            *ngIf="employeePolicy$"
            [employeeAdjustments$]="employeeAdjustments$"
            [employeePolicy$]="employeePolicy$"
            [employee]="employee$ | async"
        ></app-employee-policies>
    </div>
</div>

<ng-container *ngIf="(availableWidgets$ | async)?.employee?.datetimes?.attendance_cards && employeeID">
    <app-employee-attendance-stats [employeeID]="employeeID"></app-employee-attendance-stats>
</ng-container> <!-- end row -->

<div *ngIf="checkPermission('employeeDatetime') && (availableWidgets$ | async)?.employee?.datetimes?.chart" class="row mb-3">
    <div class="col-12">
        <app-gantt-chart (refetchRequests)="refeshData()"
                         *ngIf="employee$ | async; let employee"
                         [employee]="employee"
                         [defaultFromDate]="from"
                         [isDashboard]="false"
                         [refreshData]="refreshData"
                         [defaultInterval]="'MONTH'"
                         [withArchived]="true"
        ></app-gantt-chart>
    </div>
</div>

<div *ngIf="checkPermission('employeeDatetime') && (availableWidgets$ | async)?.employee?.datetimes?.history" class="row mb-3">
    <app-help name="employee.detail.attendance.history"></app-help>
    <div class="col-md-12">
        <app-employee-attendance-to-approve (ganttRefresh)="refeshData()"
                                            *ngIf="employee$ | async; let employee"
                                            [employee]="employee"
                                            [isStatic]="false"
                                            [refreshData]="refreshData"
                                            [isEmployeeDetail]="true">
        </app-employee-attendance-to-approve>
    </div>
</div>

<div *ngIf="permissions.includes('employeeDatetime.edit') && (availableWidgets$ | async)?.employee?.datetimes?.approvers && (employee$ | async)" class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="employee.detail.attendance.approvers"></app-help>

            <div class="card-header py-3">
                <div class="row">
                    <div class="col-sm-6 text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes.approvers' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="(approvers$ | async); let approvers" class="card-body">
                <div class="row mb-2">
                    <div class="col-6">
                        <label class="control-label mr-2" for="showForm">{{ 'employees_datetimes.auto_approve' | translate }}</label>
                    </div>
                    <div class="col-6 text-right">
                        <input
                            (click)="setAutoApprove()"
                            [checked]="autoApprove"
                            aria-controls="collapse"
                            data-switch="success"
                            id="auto_approve"
                            name="auto_approve"
                            type="checkbox"
                        >
                        <label for="auto_approve">&nbsp;</label>
                    </div>
                    <div class="col-12 text-right">
                        <small class="text-muted">
                            {{ 'employees_datetimes.auto_approve_explanation' | translate}}
                        </small>
                    </div>
                </div>

                <div [(ngbCollapse)]="autoApprove">
                    <div
                        #collapse="ngbCollapse"
                        *ngIf="approvers.active"
                        [(ngbCollapse)]="!collapseForm"
                        class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="control-label"> {{ 'employees_datetimes.active_approvers' | translate}} </label>
                                <div>
                                    <ng-container *ngFor="let approver of approvers.active; let i = index; let c = count">
                                        <span>{{ approver.fullname }}</span>
                                        <span *ngIf="i < c - 1">, </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <label class="control-label mr-2" for="showForm">{{ 'employees_datetimes.change_approvers' | translate }}</label>
                        </div>
                        <div class="col-6 text-right">
                            <input
                                (click)="setFormCollapse()"
                                [attr.aria-expanded]="collapseForm"
                                [checked]="!collapseForm"
                                aria-controls="collapse"
                                data-switch="success"
                                id="showForm"
                                name="showForm"
                                type="checkbox"
                            >
                            <label for="showForm">&nbsp;</label>
                        </div>
                    </div>

                    <form
                        (ngSubmit)="onSubmit()"
                        [formGroup]="attendanceApproversForm"
                    >
                        <div
                            #collapse="ngbCollapse"
                            [(ngbCollapse)]="collapseForm"
                        >
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label"> {{ 'employees_datetimes.select_approvers' | translate}} </label>
                                        <ng-select
                                            (change)="onApproversChange($event)"
                                            [clearable]="false"
                                            [closeOnSelect]="false"
                                            [items]="approvers.available"
                                            [multiple]="true"
                                            bindLabel="fullname"
                                            bindValue="employee_ID"
                                            class="custom"
                                            formControlName="approvers"
                                            name="approvers"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2 mb-0">
                                <div class="col-6">
                                    <label class="control-label mr-2 " for="method">{{ 'employees_datetimes.overwrite_approvers_checkbox' | translate }}</label>
                                </div>
                                <div class="col-6 text-right">
                                    <input
                                        (change)="onApproversChange(null)"
                                        data-switch="success"
                                        formControlName="method"
                                        id="method"
                                        name="method"
                                        type="checkbox"
                                    >
                                    <label for="method">&nbsp;</label>
                                </div>
                            </div>
                            <div class="row mt-0">
                                <div class="col-12">
                                    <small class="text-muted">{{ 'employees_datetimes.overwrite_approvers_checkbox_description' | translate }}</small>
                                </div>
                            </div>

                            <div *ngIf="approversResult" class="row mt-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label"> {{ 'employees_datetimes.new_approvers' | translate}} </label>
                                        <div>
                                            <ng-container *ngFor="let approver of approversResult; let i = index; let c = count">
                                                <span>{{ approver.fullname }}</span>
                                                <span *ngIf="i < c - 1">, </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row mt-2">
                    <div class="col d-flex justify-content-end">
                        <button [disabled]="!this.approversResult.length && !this.collapseForm"
                                class="btn btn-success"
                                type="button"
                                (click)="onSubmit()">
                            <span>{{ 'global.action_save' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
