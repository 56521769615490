<div class="card shadow">
  <app-help name="employee.detail.files"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-6 text-left my-auto">
        <p class="modal-title font-16 pt-2 pb-2 text-dark">
          <span>{{ 'employees.files' | translate }}</span>
        </p>
      </div>
      <div class="col-6 text-right">
          <button *ngIf="signiIntegrationEnabled" class="ml-sm-2 btn btn-icon btn-primary"
                  title="{{ 'files.action_send_signi' | translate }}"
                  (click)="showSigniEnvelope = !showSigniEnvelope"
          ><img src="assets/images/integrations/signi.png" alt="Signi" style="max-height: 20px"></button>
        <button *ngIf="employee?.permissions.createFile && (availableFeatures$ | async)?.document_generations"
          class="ml-sm-2"
          [createButton]="'document_templates.action_generate' | translate"
          (click)="openCreateDocumentByTemplateModal()"
        ></button>
      </div>
    </div>
  </div>

  <div class="card-body">

    <ng-container *ngIf="employee?.permissions.createFile">
      <app-upload
        [fileName]="employee.fullname + '.jpeg'"
        [secondaryModelName]="'file_type'"
        [secondaryModelValue]="'employee_file'"
        [modelName]="'employee_ID'"
        [modelValue]="employeeID"
        [uploadMultiple]="true"
        (success)="onFileDelete()"
      ></app-upload>
    </ng-container>

    <app-files-table
      *ngIf="employeeFiles$"
      [allowDelete]="allowDelete$ | async"
      [files$]="employeeFiles$"
      [object]="employee"
      [objectType]="'EMPLOYEE'"
      [showFileOnNewPage]="true"
      [entityType]="'employee'"
      [entityID]="employeeID"
      (refetch)="onFileDelete()"
      [showSigniEnvelope]="showSigniEnvelope"
    ></app-files-table>
  </div>
</div>
