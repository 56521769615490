<div class="modal-content">
    <div class="modal-header">
        <p
            *ngIf="employeeDateTime"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'employees_datetimes.page_title_edit' | translate }}</p>

        <p
            *ngIf="!employeeDateTime"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'employees_datetimes.page_title_create' | translate }}</p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                            (ngSubmit)="onSubmit()"
                            [formGroup]="dateTimeEditForm"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.type' | translate }}</label>
                                        <ng-select
                                            [clearable]="false"
                                            [items]="datetimeTypes"
                                            bindLabel="label"
                                            bindValue="value"
                                            class="custom"
                                            formControlName="employee_datetime_type_ID"
                                            name="type"
                                        ></ng-select>

                                        <div *ngIf="submitted && f.employee_datetime_type_ID.errors">
                                            <p
                                                *ngIf="f.employee_datetime_type_ID.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.type' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.from' | translate }}</label>

                                        <input [noCalendar]="true"
                                            [enableTime]="true"
                                            [locale]="locale$ | async"
                                            allowInput="true"
                                            altFormat="H:i"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="H:i"
                                            formControlName="from"
                                            mwlFlatpickr
                                            name="from"
                                            time24hr="true"
                                            type="text"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, true)"
                                        >

                                        <div *ngIf="submitted && f.from.errors">
                                            <p
                                                *ngIf="f.from.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.from' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.to' | translate }}</label>

                                        <input [noCalendar]="true"
                                            [locale]="locale$ | async"
                                            allowInput="true"
                                            altFormat="H:i"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="H:i"
                                            enableTime="true"
                                            formControlName="to"
                                            mwlFlatpickr
                                            name="to"
                                            time24hr="true"
                                            type="text"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, true)"
                                        >

                                        <div *ngIf="submitted && f.to.errors">
                                            <p
                                                *ngIf="f.to.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.to' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button (click)="deleteDateTime()" *ngIf="employeeDateTime && employeeDateTime?.permissions?.delete" class="btn btn-link text-muted pr-0 mr-2" type="button">
                                        <u>{{ 'employees_datetimes.action_delete' | translate }}</u>
                                    </button>
                                    <button class="btn btn-success" type="submit">
                                        {{ 'employees_datetimes.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
