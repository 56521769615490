import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ID } from '@datorama/akita';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateEditEmployeeDatetimeModalComponent } from 'src/app/employee/components/partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';
import { Employee } from 'src/app/employee/models/Employee';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { DatetimesTypesStoreService } from 'src/app/setting/services/datetimes-types-store.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { CompanyGroupService } from '../../../../company-group/state/company-group.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CreateBulkAttendanceModalComponent } from '../../../../employee/components/partials/create-bulk-attendance-modal/create-bulk-attendance-modal.component';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { EmployeeDatetimeType } from '../../../../setting/models/EmployeeDatetimeType';
import { CompanyService } from '../../../../setting/services/company.service';
import { UserService } from '../../../../setting/services/user.service';
import { FatherOfListComponent, Sort } from '../../../../ui';
import { localStorageSafe } from '../../../functions';
import { GanttCategories } from '../../../models/GanttCategories';

@Component({
    selector: 'app-gantt-chart',
    templateUrl: './gantt-chart.component.html',
    styleUrls: ['./gantt-chart.component.css']
})
export class GanttChartComponent extends FatherOfListComponent<any> implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() public employee: Employee;
    @Input() public defaultFromDate: string;
    @Input() public defaultInterval: 'WEEK' | '2WEEK' | 'MONTH';
    @Input() public isDashboard: boolean;
    @Input() public showSwitch = false;
    @Input() public isShift = false;
    @Input() public isStatic = false;
    @Input() public allowBulkMode = false;
    @Input() public refreshData: Subject<boolean>;
    @Input() public withArchived: boolean;

    @Output() public refetchRequests = new EventEmitter<string>();

    public defaultWidth = 20;
    public defaultGroupID: number;
    public filterForm: UntypedFormGroup;
    public availableCompanyGroups: Array<any>;
    public usedCompanyGroups: Array<any>;
    public loadedCompanyGroups: Array<any> = [];
    public collapsedCompanyGroups: Array<boolean> = [];
    public ganttData: Array<any> = [];
    public ganttCategories: Array<GanttCategories> = [];
    public dataLoading: Array<boolean> = [];
    public chartLoading = true;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public bulkMode = false;
    public bulkSelected: { ID: string, employee_ID: ID, employee_datetime_ID?: ID, start: number, end: number }[] = [];
    public defaultFilters = this._authService.loggedUser?.default_filters;
    public sort: Sort<any>;
    public lsName = 'ganntChartDashboard';
    public availableWidgets$: Observable<AvailableWidgets>;
    public intervalItems = [
        {label: this.translateService.instant('employees_datetimes.filter_range_1WEEK'), value: 'WEEK'},
        {label: this.translateService.instant('employees_datetimes.filter_range_2WEEK'), value: '2WEEK'},
        {label: this.translateService.instant('employees_datetimes.filter_range_1MONTH'), value: 'MONTH'}
    ];

    private _isEmployeeDetailDatetimesPage = false;
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        public fpHelper: FlatpickrHelper,
        public translateService: TranslateService,
        private _attendanceService: AttendanceService,
        private _groupService: CompanyGroupService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _modalService: NgbModal,
        private _router: Router,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _companyService: CompanyService,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
        protected _ngZone: NgZone,
        protected _cdr: ChangeDetectorRef
    ) {
        super(_ngZone, _cdr, _authService, _userService);
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this.filterForm = this._fb.group({
            range_start_date: [this.defaultFromDate],
            range_end_date: [null],
            interval: [this.defaultInterval],
            group_by_company_group: [true],
            expend_all_company_groups: [false],
            types: [null]
        });
        const routeRegex = /^\/employee\/\d+\/detail\/datetimes$/;
        if (routeRegex.test(this._router.url)) {
            this._isEmployeeDetailDatetimesPage = true;
        }
        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(
                map(types => {
                    return types.filter(type => type.is_shift === this.isShift);
                }));

        this.filterForm[`defaultValue`] = this.filterForm.value;

        if (this._router.url.includes('attendance/shifts/chart')) {
            this.lsName = 'ganntChartShifts';
        }
        if (this._router.url.includes('attendance/chart')) {
            this.lsName = 'ganntChartAttendance';
        }
        if (this._router.url.includes('detail/datetimes')) {
            this.lsName = 'ganntChartEmployeeDetail';
        }

        this.useLocalStorage(this.filterForm, this.lsName);

        // do not remember the range start date
        this.onRangeChange(this.filterForm.value.interval, true);

        this._groupService.getByEmployee(this._isEmployeeDetailDatetimesPage ? this.employee.employee_ID : null)
            .subscribe((result: any) => {
                this.availableCompanyGroups = result?.groups;
                this.usedCompanyGroups = result?.groups;
                this.defaultGroupID = this.employee ? (this.employee.company_group ? this.employee.company_group.company_group_ID : 'other') : result?.default_group_ID;
                let collapse = true;
                if (this.filterForm.value.group_by_company_group === true && this.filterForm.value.expend_all_company_groups === true) {
                    const availableCompanyGroupsIds = this.availableCompanyGroups.map(group => group.company_group_ID);
                    this.refreshLoadedData(availableCompanyGroupsIds);
                    collapse = false;
                } else if (this.filterForm.value.group_by_company_group === true && this.filterForm.value.expend_all_company_groups === false && this.defaultFilters?.list_components_config?.[this.lsName]?.form?.company_group_IDs?.length > 0) {
                    // this has to be here again, because the refreshLoadedData function is then overriden
                    result.groups.forEach((group: any) => {
                        this.collapsedCompanyGroups[group.company_group_ID] = true;
                    });
                    this.refreshLoadedData(this.defaultFilters.list_components_config?.[this.lsName]?.form?.company_group_IDs);
                } else if (this.filterForm.value.group_by_company_group === false) {
                    this.refreshLoadedData(this.loadedCompanyGroups);
                } else {
                    if (this.defaultGroupID) {
                        this.refreshDataByTeam(this.defaultGroupID);
                    }
                }

                if (!(this.filterForm.value.group_by_company_group === true && this.filterForm.value.expend_all_company_groups === false && this.defaultFilters?.list_components_config?.[this.lsName]?.form?.company_group_IDs?.length > 0)) {
                    result.groups.forEach((group: any) => {
                        this.collapsedCompanyGroups[group.company_group_ID] = collapse;
                    });
                }
            });
        if (this.refreshData) {
            this.refreshData.subscribe(() => {
                if (this.loadedCompanyGroups.length > 0) {
                    this.refreshLoadedData(this.loadedCompanyGroups);
                }
            });
        }
    }

    public countIntervalDates(): void {
        const interval = this.filterForm.value.interval;
        const dateFrom = this.filterForm.value.range_start_date;
        let dateTo = null;
        if (interval === 'WEEK') {
            dateTo = moment(dateFrom).add(6, 'days').format('YYYY-MM-DD');
        }

        if (interval === '2WEEK') {
            dateTo = moment(dateFrom).add(13, 'days').format('YYYY-MM-DD');
        }

        if (interval === 'MONTH') {
            dateTo = moment(dateFrom).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
        }

        this.filterForm.patchValue({
            range_end_date: dateTo
        });
    }

    protected _fetchListData(): void {
    }

    public trackByFn(index: number, item: any): number | string {
        return undefined;
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();
        this.countIntervalDates();
        this.saveFormConfigToLocalStorage({...this.filterForm.value, company_group_IDs: this.getShownCompanyGroupsIds()}, 'form');
        this.refreshLoadedData(this.loadedCompanyGroups);
    }

    public setCollapse(companyGroupID: number): void {
        this.collapsedCompanyGroups[companyGroupID] = !this.collapsedCompanyGroups[companyGroupID];
    }

    public refreshLoadedData(companyGroupIDs: any[]): void {
        const employeeIDs = this._isEmployeeDetailDatetimesPage ? [this.employee.employee_ID] : [];
        const form = this.filterForm.value;
        if (form.group_by_company_group && form.expend_all_company_groups) {
            this.usedCompanyGroups = this.availableCompanyGroups;
            this.loadedCompanyGroups = companyGroupIDs;
            this.availableCompanyGroups.forEach(group => {
                this.collapsedCompanyGroups[group.company_group_ID] = false;
                this.loadedCompanyGroups.push(group.company_group_ID);
                this.dataLoading[group.company_group_ID] = true;
            });
        } else if (form.group_by_company_group && !form.expend_all_company_groups && this.defaultFilters?.list_components_config?.[this.lsName]?.form?.company_group_IDs?.length > 0) {
            this.usedCompanyGroups = this.availableCompanyGroups;
            this.loadedCompanyGroups = companyGroupIDs;
            companyGroupIDs.forEach(group => {
                this.collapsedCompanyGroups[group] = false;
                this.loadedCompanyGroups.push(group);
                this.dataLoading[group] = true;
            });
        } else if (form.group_by_company_group) {
            this.usedCompanyGroups = this.availableCompanyGroups;
        } else {
            for (const i of this.availableCompanyGroups) {
                this.loadedCompanyGroups.push(i.company_group_ID);
            }
            this.usedCompanyGroups = [{company_group_ID: 'all', name: 'all'}];
        }
        companyGroupIDs = companyGroupIDs.filter((value, index, self) => self.indexOf(value) === index);
        this._attendanceService.getDateTimesForGanttChart(form.range_start_date || this.defaultFromDate, form.range_end_date, this.isShift, companyGroupIDs, employeeIDs, true, form.group_by_company_group, form.expend_all_company_groups, form.types, this.withArchived, this.lsName, this.getShownCompanyGroupsIds())
            .subscribe(sub => {
                this.ganttCategories = sub?.categories;
                this.ganttData = sub?.data;
                if (form.group_by_company_group && (form.expend_all_company_groups || (!form.expend_all_company_groups && this.defaultFilters?.list_components_config?.[this.lsName]?.form?.company_group_IDs?.length > 0))) {
                    this.dataLoading = this.dataLoading.map(loading => !loading);
                }
                this.chartLoading = false;
            });
    }

    public refreshDataByTeam(companyGroupID: number, userClicked = false): void {
        const employeeIDs = this._isEmployeeDetailDatetimesPage ? [this.employee.employee_ID] : [];
        this.setCollapse(companyGroupID);
        if (!this.loadedCompanyGroups.includes(companyGroupID)) {
            this.dataLoading[companyGroupID] = true;
            const includeCategories = this.ganttCategories.length <= 0;
            const form = this.filterForm.value;
            this._attendanceService.getDateTimesForGanttChart(form.range_start_date || this.defaultFromDate, form.range_end_date, this.isShift, [companyGroupID], employeeIDs, includeCategories, form.group_by_company_group, form.expend_all_company_groups, form.types, this.withArchived, this.lsName, this.getShownCompanyGroupsIds())
                .subscribe(sub => {
                    if (sub?.categories) {
                        this.ganttCategories = sub?.categories;
                    }
                    this.ganttData[companyGroupID] = sub?.data[companyGroupID];
                    this.loadedCompanyGroups.push(companyGroupID);
                    this.collapsedCompanyGroups[companyGroupID] = false;
                    this.dataLoading[companyGroupID] = false;
                    this.chartLoading = false;
                });
        }
        // save expanded company groups for next use
        if (userClicked) {
            const companyGroupIDs = [];
            for (let i = 0; i < this.collapsedCompanyGroups.length; i++) {
                if (this.collapsedCompanyGroups[i] === false) {
                    companyGroupIDs.push(i);
                }
            }
            if (this.collapsedCompanyGroups[`other`] === false) {
                companyGroupIDs.push('other');
            }
            if (this._authService.loggedUser?.default_filters) {
                this.saveFormConfigToLocalStorage({...this.filterForm.value, company_group_IDs: companyGroupIDs}, 'form');
                this._authService.loggedUser.default_filters.list_components_config[this.lsName] = JSON.parse(localStorageSafe.getItem('list_components_config') || '{}')[this.lsName];
            }
        }
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public getShownEmployeeIds(): number[] {
        let employeeIDsToExport = [];
        // expanded by company groups
        for (let i = 0; i < this.collapsedCompanyGroups.length; i++) {
            if (this.collapsedCompanyGroups[i] === false && this.ganttData[i]) {
                employeeIDsToExport = employeeIDsToExport.concat(this.ganttData[i]?.map(item => item.employee.employee_ID));
            }
        }
        if (this.collapsedCompanyGroups[`other`] === false && this.ganttData[`other`]) {
            employeeIDsToExport = employeeIDsToExport.concat(this.ganttData[`other`]?.map(item => item.employee.employee_ID));
        }

        // expanded all employees
        if (this.ganttData[`all`]?.length > 0) {
            employeeIDsToExport = employeeIDsToExport.concat(this.ganttData[`all`]?.map(item => item.employee.employee_ID));
        }
        return employeeIDsToExport;
    }

    public openDatetimeEditModal(box: any, employee: Employee, highlight: boolean, showModal: boolean, empty = false): void {
        if (this.bulkMode && (highlight || showModal)) {
            const id = `${employee.employee_ID.toString()}-${box.start_timestamp.toString()}-${box.end_timestamp.toString()}`;
            if (this.bulkSelected.find(item => item.ID === id)) {
                this.bulkSelected = this.bulkSelected.filter(item => item.ID !== id);
            } else {
                this.bulkSelected.push({
                    ID: id,
                    employee_ID: employee.employee_ID,
                    employee_datetime_ID: box.employee_datetime_ID,
                    start: box.start_timestamp,
                    end: box.end_timestamp
                });
            }

            return;
        }

        if (highlight || showModal) {
            if (box.type === 'EMPTY' || empty) {
                const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

                modalRef.componentInstance.employeeID = employee.employee_ID;
                modalRef.componentInstance.start = moment.unix(box.start_timestamp).format('YYYY-MM-DD');
                modalRef.componentInstance.end = moment.unix(box.start_timestamp).format('YYYY-MM-DD');
                modalRef.componentInstance.isShift = this.isShift;

                modalRef.result
                    .then(
                        () => {
                            this.refetchData();
                        }, () => {}
                    );
            } else {
                const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});
                modalRef.componentInstance.employeeID = employee.employee_ID;
                modalRef.componentInstance.isShift = this.isShift;
                modalRef.componentInstance.isLoading = true;

                this._attendanceService.getEmployeeDateTimeByID(employee.employee_ID, box.employee_datetime_ID, 'extend')
                    .subscribe(dateTime => {
                        modalRef.componentInstance.employeeDateTime = dateTime;
                        modalRef.componentInstance.init();
                        modalRef.componentInstance.isLoading = false;

                        modalRef.result
                            .then(
                                () => {
                                    this.refetchData();
                                },
                                () => {}
                            );
                    });
            }
        }
    }

    public getShownCompanyGroupsIds(): any[] {
        return this.availableCompanyGroups.filter(group => this.collapsedCompanyGroups[group.company_group_ID] === false || this.dataLoading[group.company_group_ID] === true).map(group => group.company_group_ID);
    }

    public getStripeColor(color: string): string {

        // Convert hex color string to RGB
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);

        // Calculate color brightness
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return true if color is light, false if color is dark
        return brightness > 128 ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)';
    }

    public getShortcutColor(color: string): string {

        // Convert hex color string to RGB
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);

        // Calculate color brightness
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return true if color is light, false if color is dark
        return brightness > 128 ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';
    }

    public switchToPlan(): void {
        const url = `/employee/attendance/${this.isShift ? 'shifts/chart' : 'chart'}?type=plan`;
        void this._router.navigateByUrl(url);
        localStorageSafe.setItem(`${this.isShift ? 'shifts' : 'attendance'}_chart_type`, 'plan');
    }

    public toggleBulkMode(): void {
        this.bulkSelected = [];
        this.bulkMode = !this.bulkMode;
    }

    public isRectSelected(datetimePart: any, employeeID: any): boolean {
        if (this.bulkMode) {
            const id = `${employeeID.toString()}-${datetimePart.start_timestamp.toString()}-${datetimePart.end_timestamp.toString()}`;
            if (this.bulkSelected.find(item => item.ID === id)) {
                return true;
            }
        }

        return false;
    }

    public openBulkModal(): void {
        const modalRef = this._modalService.open(CreateBulkAttendanceModalComponent, {centered: true});

        modalRef.componentInstance.selectedData = this.bulkSelected;
        modalRef.componentInstance.isShift = this.isShift;

        modalRef.result.then(() => {
            this.refetchData();
            this.bulkSelected = [];
        }, () => {});
    }

    public refetchData(): void {
        this._attendanceService.refreshTimestamps$.next();
        this.refreshLoadedData(this.loadedCompanyGroups);
        this.refetchRequests.emit('refetch');
    }

    public downloadAttendance() {
        this._attendanceService.downloadAllDatetimeExportsRange(this.filterForm.value.range_start_date, this.filterForm.value.range_end_date, this.getShownEmployeeIds(), 'aggregated_excel');
    }

    public countFromDate(add: boolean): void {
        let usedDate = add ? moment(this.filterForm.value.range_start_date).add(7, 'days').format('YYYY-MM-DD') : moment(this.filterForm.value.range_start_date).subtract(7, 'days').format('YYYY-MM-DD');

        if (this.filterForm.value.interval === '2WEEK') {
            usedDate = add ? moment(this.filterForm.value.range_start_date).add(14, 'days').format('YYYY-MM-DD') : moment(this.filterForm.value.range_start_date).subtract(14, 'days').format('YYYY-MM-DD');
        }

        if (this.filterForm.value.interval === 'MONTH') {
            usedDate = add ? moment(this.filterForm.value.range_start_date).add(1, 'month').format('YYYY-MM-DD') : moment(this.filterForm.value.range_start_date).subtract(1, 'month').format('YYYY-MM-DD');
        }

        this.filterForm.patchValue({
            range_start_date: usedDate
        });
        this.onSubmit();
    }

    public onDateChange(date: Date, range: 'month' | 'week'): void {
        this.filterForm.patchValue({
            range_start_date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
        });
        this.countIntervalDates();
    }

    public onRangeChange(event: string, init = false): void {
        const usedDate = init ? this.defaultFromDate : this.filterForm.value.range_start_date;
        if (event === 'WEEK' || event === '2WEEK') {
            this.filterForm.patchValue({
                range_start_date: moment(usedDate).startOf('week').add(1, 'day').format('YYYY-MM-DD')
            });
        }
        if (event === 'MONTH') {
            this.filterForm.patchValue({
                range_start_date: moment(usedDate).startOf('month').format('YYYY-MM-DD')
            });
        }
        this.countIntervalDates();
    }
}
