import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CreateEditEmployeeDatetimeModalComponent } from '../../partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';

@Component({
    selector: 'app-employee-attendance-detail',
    templateUrl: './employee-attendance-detail.component.html',
    styleUrls: ['./employee-attendance-detail.component.css']
})
export class EmployeeAttendanceDetailComponent implements OnInit, OnDestroy {
    public employeeDateTime: EmployeeDateTime;
    public employeeDateTimeID: number;
    public employeeID: number;
    public routeParamsSubscription: Subscription;
    public from: Date;
    public to: Date;

    public constructor(
        public authService: AuthenticationService,
        private _attendanceService: AttendanceService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _router: Router
    ) { }

    public ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.employeeDateTimeID = parseInt(this._route.snapshot.paramMap.get('dateTimeID'), 10);

        this.employeeID = parseInt(this._route.snapshot.paramMap.get('id'), 10);

        this.routeParamsSubscription = this._route.params
            .pipe(
                map(params => parseInt(params.dateTimeID, 10)),
                switchMap(dateTimeID => {
                    if (dateTimeID) {
                        return this._attendanceService.getEmployeeDateTimeByID(this.employeeID, dateTimeID, 'extend');
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe(datetime => {
                this.employeeDateTime = datetime;
                this.from = new Date(datetime.from);
                this.to = new Date(datetime.to);
            });
    }

    public approveDatetime(): void {
        this._attendanceService.saveEmployeeDateTimeState({state: 'approved'} as EmployeeDateTime, this.employeeDateTimeID)
            .then(() => this.getDateTime());
    }

    public declineDatetime(): void {
        this._attendanceService.saveEmployeeDateTimeState({state: 'denied'} as EmployeeDateTime, this.employeeDateTimeID)
            .then(() => this.getDateTime());
    }

    public getDateTime(): void {
        this._attendanceService.getEmployeeDateTimeByID(this.employeeID, this.employeeDateTimeID, 'extend')
            .subscribe(datetime => {
                this.employeeDateTime = datetime;
                this.from = new Date(datetime.from);
                this.to = new Date(datetime.to);
            });
    }

    public openDatetimeEditModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

        modalRef.componentInstance.employeeDateTime = this.employeeDateTime;
        modalRef.componentInstance.employeeID = this.employeeID;
        modalRef.componentInstance.isShift = this.employeeDateTime.type.is_shift;
        modalRef.componentInstance.isTimer = this.employeeDateTime.type.timer;
        modalRef.componentInstance.isPlan = this.employeeDateTime.type.can_plan;

        modalRef.result
            .then(
                (result) => {
                    // if the modal is closed with result 'cancel', it means delete action was performed
                    if (result === 'cancel') {
                        void this._router.navigateByUrl('/employee/attendance/history');
                    } else {
                        this.getDateTime();
                    }
                },
                () => {}
            );
    }

    public recalculateDatetimes() {
        this._attendanceService.recalculateDatetimes(null, this.employeeDateTimeID, null, null)
            .then(
                result => {
                    if (result) {
                        this.getDateTime();
                    }
                },
                () => { }
            );
    }
}
