<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span *ngIf="datetimeType">{{ 'employees_datetimes_types.page_title_edit' | translate }}</span>
            <span *ngIf="!datetimeType">{{ 'employees_datetimes_types.page_title_create' | translate }}</span>
        </p>

        <button aria-label="Close" class="close" (click)="activeModal.dismiss()">
            <span aria-hidden="true" class="font-24 text-muted">
                <i class="mdi mdi-close"></i>
            </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form autocomplete="off" [formGroup]="datetimeTypeEditForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees_datetimes_types.name' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="name"
                                            name="name"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.name.errors">
                                            <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes_types.name' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="is_active">
                                            <span>{{ 'employees_datetimes_types.is_active' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="is_active" id="is_active" name="is_active" type="checkbox">
                                        <label for="is_active">&nbsp;</label>
                                    </div>

                                    <div *ngIf="submitted && f.is_active.errors">
                                        <p *ngIf="f.is_active.errors.required" class="text-danger validation-text">
                                            <span>{{ 'angular_validation.field' | translate }} </span>
                                            <span>{{ 'employees_datetimes_types.is_active' | translate }} </span>
                                            <span>{{ 'angular_validation.required' | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees_datetimes_types.abbreviation' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="abbreviation"
                                            name="abbreviation"
                                            type="text"
                                            maxlength="16"
                                        >

                                        <div *ngIf="submitted && f.name.errors">
                                            <p *ngIf="f.abbreviation.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes_types.abbreviation' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees_datetimes_types.color' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="color"
                                            name="color"
                                            type="text"
                                            [colorPicker]="datetimeTypeEditForm.get('color').value"
                                            [cpPosition]="'bottom'"
                                            (colorPickerChange)="datetimeTypeEditForm.get('color').setValue($event)"
                                        >

                                        <div *ngIf="submitted && f.color.errors">
                                            <p *ngIf="f.color.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes_types.color' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mb-0">
                                        <label class="control-label mb-0" for="timer">
                                            <span>{{ 'employees_datetimes_types.timer' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div class="form-group mb-1">
                                        <div>
                                            <input data-switch="success" formControlName="timer" id="timer" name="timer" type="checkbox">
                                            <label class="mb-0" for="timer">&nbsp;</label>
                                        </div>

                                        <div *ngIf="submitted && f.timer.errors">
                                            <p *ngIf="f.timer.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes_types.timer' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 text-right mb-3">
                                    <small class="text-muted">
                                        <span>{{ 'employees_datetimes_types.timer_description' | translate }}</span>
                                    </small>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label mb-0" for="can_plan">
                                            <span>{{ 'employees_datetimes_types.can_plan' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="can_plan" id="can_plan" name="can_plan" type="checkbox">
                                        <label class="mb-0" for="can_plan">&nbsp;</label>
                                    </div>

                                    <div *ngIf="submitted && f.can_plan.errors">
                                        <p *ngIf="f.can_plan.errors.required" class="text-danger validation-text">
                                            <span>{{ 'angular_validation.field' | translate }} </span>
                                            <span>{{ 'employees_datetimes_types.can_plan' | translate }} </span>
                                            <span>{{ 'angular_validation.required' | translate }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-12 text-right mb-3">
                                    <small class="text-muted">
                                        <span>{{ 'employees_datetimes_types.can_plan_description' | translate }}</span>
                                    </small>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label mb-0" for="is_shift">
                                            <span>{{ 'employees_datetimes_types.is_shift' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="is_shift" id="is_shift" name="is_shift" type="checkbox">
                                        <label class="mb-0" for="is_shift">&nbsp;</label>
                                    </div>

                                    <div *ngIf="submitted && f.is_shift.errors">
                                        <p *ngIf="f.is_shift.errors.required" class="text-danger validation-text">
                                            <span>{{ 'angular_validation.field' | translate }} </span>
                                            <span>{{ 'employees_datetimes_types.is_shift' | translate }} </span>
                                            <span>{{ 'angular_validation.required' | translate }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-12 text-right mb-3">
                                    <small class="text-muted">
                                        <span>{{ 'employees_datetimes_types.is_shift_description' | translate }}</span>
                                    </small>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-12 text-right mb-1">
                                    <small class="text-muted">
                                        <span>{{ 'employees_datetimes_types.type_combine_description' | translate }}</span>
                                    </small>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label mb-0" for="worktime_classification">
                                            <span>{{ 'employees_datetimes_types.worktime_classification' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 mb-1">
                                    <div id="worktime_classification" class="btn-group btn-group-toggle d-flex" data-toggle="buttons">
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "datetimeTypeEditForm.controls.worktime_classification.value === 'WORKTIME'">
                                            <input type="radio" formControlName="worktime_classification" name="worktime_classification" value="WORKTIME" checked>{{ 'employees_datetimes_types.classification.WORKTIME' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "datetimeTypeEditForm.controls.worktime_classification.value === 'NEUTRAL'">
                                            <input type="radio" formControlName="worktime_classification" name="worktime_classification" value="NEUTRAL">{{ 'employees_datetimes_types.classification.NEUTRAL' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "datetimeTypeEditForm.controls.worktime_classification.value === 'ABSENCE'">
                                            <input type="radio" formControlName="worktime_classification" name="worktime_classification" value="ABSENCE">{{ 'employees_datetimes_types.classification.ABSENCE' | translate }}
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 text-right">
                                    <small class="text-muted">
                                        <span>{{ 'employees_datetimes_types.' + datetimeTypeEditForm.controls.worktime_classification.value + '_description' | translate }}</span>
                                    </small>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="is_approvable">
                                            <span>{{ 'employees_datetimes_types.is_approvable' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input
                                            data-switch="success"
                                            formControlName="is_approvable"
                                            id="is_approvable"
                                            name="is_approvable"
                                            type="checkbox"
                                        >
                                        <label for="is_approvable">&nbsp;</label>
                                    </div>

                                    <div *ngIf="submitted && f.is_approvable.errors">
                                        <p *ngIf="f.is_approvable.errors.required" class="text-danger validation-text">
                                            <span>{{ 'angular_validation.field' | translate }} </span>
                                            <span>{{ 'employees_datetimes_types.is_approvable' | translate}} </span>
                                            <span>{{ 'angular_validation.required' | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="show_shift_duration">
                                            <span>{{ 'employees_datetimes_types.custom_shift_duration' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input
                                            data-switch="success"
                                            id="show_shift_duration"
                                            type="checkbox"
                                            [checked]="showShiftDuration"
                                            (change)="setShowShiftDuration()"
                                        >
                                        <label for="show_shift_duration">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showShiftDuration" class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control"
                                               type="number"
                                               min="0"
                                               formControlName="custom_shift_duration"
                                               name="custom_shift_duration">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="show_custom_from_to">
                                            <span>{{ 'employees_datetimes_types.show_custom_from_to' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input
                                            data-switch="success"
                                            id="show_custom_from_to"
                                            type="checkbox"
                                            [checked]="showCustomFromTo"
                                            (change)="showCustomFromTo = !showCustomFromTo"
                                        >
                                        <label for="show_custom_from_to">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <ng-container formGroupName="custom_from_to" *ngIf="showCustomFromTo">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_datetimes_types.custom_from' | translate }}</span>
                                            </label>
                                            <input
                                                mwlFlatpickr
                                                [noCalendar]="true"
                                                [enableTime]="true"
                                                [time24hr]="true"
                                                allowInput="true"
                                                altFormat="H:i"
                                                altInput="true"
                                                class="form-control"
                                                dateFormat="H:i"
                                                formControlName="from"
                                                name="from"
                                                type="text"
                                                [locale]="locale$ | async"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_datetimes_types.custom_to' | translate }}</span>
                                            </label>
                                            <input
                                                mwlFlatpickr
                                                [noCalendar]="true"
                                                [enableTime]="true"
                                                [time24hr]="true"
                                                allowInput="true"
                                                altFormat="H:i"
                                                altInput="true"
                                                class="form-control"
                                                dateFormat="H:i"
                                                formControlName="to"
                                                name="to"
                                                type="text"
                                                [locale]="locale$ | async"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button *ngIf="datetimeType && datetimeType.can_delete" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="deleteDatetimeType()">
                                        <u>{{ 'employees_datetimes_types.action_delete' | translate }}</u>
                                    </button>
                                    <button class="btn btn-success">
                                        <span>{{ 'employees_datetimes_types.action_save' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
