<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark"
        >{{ 'employees_datetimes.bulk_create_page_title_edit' | translate }}</p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                            (ngSubmit)="onSubmit()"
                            [formGroup]="form"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.type' | translate }}</label>

                                        <ng-select
                                            [clearable]="false"
                                            [items]="datetimeTypes$ | async"
                                            bindLabel="name"
                                            bindValue="employee_datetime_type_ID"
                                            class="custom"
                                            formControlName="employee_datetime_type_id"
                                            name="type"
                                            (change)="onTypeChanged($event)"
                                        ></ng-select>

                                        <div *ngIf="submitted && f.employee_datetime_type_ID?.errors">
                                            <p
                                                *ngIf="f.employee_datetime_type_ID?.errors?.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.type' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                *ngIf="canCreate"
                                class="row"
                            >
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.state' | translate }}</label>

                                        <ng-select
                                            class="custom"
                                            formControlName="state"
                                            name="state"
                                        >
                                            <ng-option value="APPROVED">
                                                <span>{{ 'employees_datetimes_states.type_APPROVED' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="DENIED">
                                                <span>{{ 'employees_datetimes_states.type_DENIED' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="NEW">
                                                <span>{{ 'employees_datetimes_states.type_NEW' | translate }}</span>
                                            </ng-option>
                                        </ng-select>

                                        <div *ngIf="submitted && f.state.errors">
                                            <p
                                                *ngIf="f.state.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.state' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row mb-3">
                                <div class="col-12">
                                    <div id="set_on" class="btn-group btn-group-toggle d-flex" data-toggle="buttons">
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'TIME'">
                                            <input (change)="onSetOnChange()" type="radio" formControlName="set_on" name="set_on" value="TIME" checked>{{ 'employees_datetimes.set_on_TIME' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'SHIFT'">
                                            <input (change)="onSetOnChange()" type="radio" formControlName="set_on" name="set_on" value="SHIFT">{{ 'employees_datetimes.set_on_SHIFT' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'HALF_SHIFT'">
                                            <input (change)="onSetOnChange()" type="radio" formControlName="set_on" name="set_on" value="HALF_SHIFT">{{ 'employees_datetimes.set_on_HALF_SHIFT' | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="f.set_on.value === 'TIME'" class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label required-label">{{ 'employees_datetimes.from' | translate }}</label>

                                        <input
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                            [enableTime]="true"
                                            [locale]="locale$ | async"
                                            allowInput="true"
                                            altFormat="H:i"
                                            altInput="true"
                                            class="form-control"
                                            [noCalendar]="true"
                                            dateFormat="H:i"
                                            formControlName="from"
                                            mwlFlatpickr
                                            name="from"
                                            time24hr="true"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.from?.errors">
                                            <p
                                                *ngIf="f.from?.errors?.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.from' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="f.set_on.value === 'TIME'" class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label required-label">{{ 'employees_datetimes.to' | translate }}</label>

                                        <input
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                            [locale]="locale$ | async"
                                            allowInput="true"
                                            altFormat="H:i"
                                            altInput="true"
                                            class="form-control"
                                            [noCalendar]="true"
                                            dateFormat="H:i"
                                            enableTime="true"
                                            formControlName="to"
                                            mwlFlatpickr
                                            name="to"
                                            time24hr="true"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.to?.errors">
                                            <p
                                                *ngIf="f.to?.errors?.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.to' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.note' | translate }}</label>

                                        <input
                                            class="form-control"
                                            formControlName="note"
                                            name="note"
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button class="btn btn-success" type="submit">
                                        {{ 'employees_datetimes.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
