import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { CreateEditEmployeeDatetimePartModalComponent } from '../create-edit-employee-datetime-part-modal/create-edit-employee-datetime-part-modal.component';

@Component({
    selector: 'app-employee-attendance-parts',
    templateUrl: './employee-attendance-parts.component.html',
    styleUrls: ['./employee-attendance-parts.component.css']
})
export class EmployeeAttendancePartsComponent {
    @Input()
    public employeeID: number;

    @Input()
    public employeeDateTime: EmployeeDateTime;

    @Output()
    public refetchDateTime = new EventEmitter<string>();

    public constructor(private _modalService: NgbModal) { }

    public openEditModal(datetime: EmployeeDateTime): void {
        const modalRef = this._modalService.open(CreateEditEmployeeDatetimePartModalComponent, {centered: true});

        modalRef.componentInstance.employeeDateTime = datetime;
        modalRef.componentInstance.parentDateTime = this.employeeDateTime;
        modalRef.componentInstance.employeeID = this.employeeID;

        modalRef.result
            .then(
                () => this.refetchDateTime.emit('refresh'),
                () => { }
            );
    }
}
