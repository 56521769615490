<ng-template
  *ngIf="icon === 'document'"
  [ngTemplateOutlet]="documentTemplate"
></ng-template>

<ng-template
  *ngIf="icon === 'image'"
  [ngTemplateOutlet]="imageTemplate"
></ng-template>

<ng-template
  *ngIf="icon === 'pdf'"
  [ngTemplateOutlet]="pdfTemplate"
></ng-template>

<ng-template
  *ngIf="icon === 'presentation'"
  [ngTemplateOutlet]="presentationTemplate"
></ng-template>

<ng-template
  *ngIf="icon === 'spreadsheet'"
  [ngTemplateOutlet]="spreadsheetTemplate"
></ng-template>

<ng-template
  *ngIf="icon === NOT_SUPPORTED"
  [ngTemplateOutlet]="unknownTemplate"
></ng-template>

<ng-template #documentTemplate>
  <div class="icon-wrapper">
    <svg
      [attr.height]="heightInPx + 'px'"
      [attr.width]="widthInPx + 'px'"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      version="1.1"
      viewBox="0 0 55.48 74.18"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="file-type-blue"
          d="M9.65 0l24.82 0 21.01 21.92 0 42.61c0,5.33 -4.32,9.65 -9.65,9.65l-36.18 0c-5.33,0 -9.65,-4.32 -9.65,-9.65l0 -54.88c0,-5.33 4.32,-9.65 9.65,-9.65z"
        />

        <polygon
          class="file-type-white-semitransparent"
          points="34.44,0 34.44,21.74 55.48,21.74 "
        />

        <path
          class="file-type-white-nonzero"
          d="M8.96 52.93l0 -12.28 4.35 0c0.87,0 1.68,0.13 2.43,0.37 0.75,0.25 1.43,0.61 2.04,1.09 0.61,0.48 1.09,1.12 1.44,1.92 0.35,0.8 0.53,1.72 0.53,2.76 0,1.04 -0.18,1.96 -0.53,2.76 -0.35,0.8 -0.83,1.44 -1.44,1.92 -0.61,0.48 -1.29,0.84 -2.04,1.09 -0.75,0.24 -1.56,0.37 -2.43,0.37l-4.35 0zm3.07 -2.67l0.91 0c0.49,0 0.95,-0.06 1.36,-0.17 0.42,-0.12 0.8,-0.31 1.16,-0.56 0.36,-0.25 0.64,-0.61 0.84,-1.08 0.21,-0.46 0.31,-1.02 0.31,-1.66 0,-0.64 -0.1,-1.2 -0.31,-1.67 -0.2,-0.46 -0.48,-0.82 -0.84,-1.07 -0.36,-0.26 -0.74,-0.44 -1.16,-0.56 -0.41,-0.11 -0.87,-0.17 -1.36,-0.17l-0.91 0 0 6.94zm15.17 2.81c-1.85,0 -3.38,-0.6 -4.59,-1.79 -1.21,-1.19 -1.81,-2.69 -1.81,-4.49 0,-1.8 0.6,-3.3 1.81,-4.49 1.21,-1.19 2.74,-1.79 4.59,-1.79 1.82,0 3.33,0.6 4.54,1.79 1.2,1.19 1.8,2.69 1.8,4.49 0,1.8 -0.6,3.3 -1.8,4.49 -1.21,1.19 -2.72,1.79 -4.54,1.79zm-2.36 -3.69c0.61,0.68 1.39,1.02 2.34,1.02 0.95,0 1.72,-0.34 2.33,-1.02 0.61,-0.69 0.91,-1.55 0.91,-2.59 0,-1.04 -0.3,-1.9 -0.91,-2.59 -0.61,-0.68 -1.38,-1.02 -2.33,-1.02 -0.95,0 -1.73,0.34 -2.34,1.02 -0.61,0.69 -0.92,1.55 -0.92,2.59 0,1.04 0.31,1.9 0.92,2.59zm16 3.69c-1.79,0 -3.28,-0.56 -4.46,-1.66 -1.19,-1.11 -1.78,-2.65 -1.78,-4.62 0,-1.96 0.6,-3.5 1.8,-4.61 1.21,-1.11 2.68,-1.67 4.44,-1.67 1.59,0 2.89,0.39 3.92,1.18 1.02,0.78 1.61,1.82 1.76,3.12l-3.1 0.63c-0.13,-0.68 -0.44,-1.23 -0.92,-1.64 -0.48,-0.41 -1.04,-0.62 -1.68,-0.62 -0.88,0 -1.61,0.31 -2.2,0.94 -0.59,0.64 -0.89,1.52 -0.89,2.67 0,1.15 0.3,2.03 0.88,2.66 0.59,0.64 1.32,0.95 2.21,0.95 0.64,0 1.19,-0.18 1.64,-0.54 0.45,-0.36 0.73,-0.84 0.85,-1.44l3.17 0.72c-0.29,1.24 -0.93,2.2 -1.93,2.89 -0.99,0.69 -2.23,1.04 -3.71,1.04z"
        />
      </g>
    </svg>
    <ng-container [ngTemplateOutlet]="oneDriveTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="signiTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #imageTemplate>
  <div class="icon-wrapper">
    <svg
        [attr.height]="heightInPx + 'px'"
        [attr.width]="widthInPx + 'px'"
        style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
        version="1.1"
        viewBox="0 0 17.69 23.65"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <g>
      <path
          class="file-type-light-green"
          d="M3.08 0l7.91 0 6.7 6.99 0 13.58c0,1.7 -1.38,3.08 -3.08,3.08l-11.53 0c-1.7,0 -3.08,-1.38 -3.08,-3.08l0 -17.49c0,-1.7 1.38,-3.08 3.08,-3.08z"
      />

      <polygon
          class="file-type-white-semitransparent"
          points="10.98,0 10.98,6.93 17.69,6.93 "
      />

      <path
          class="file-type-white"
          d="M11.85 11.82l-6.01 0c-0.45,0 -0.82,0.37 -0.82,0.82l0 3.82c0,0.45 0.37,0.82 0.82,0.82l6.01 0c0.45,0 0.81,-0.37 0.81,-0.82l0 -3.82c0,-0.45 -0.36,-0.82 -0.81,-0.82zm-4.37 1.03c0.49,0 0.88,0.4 0.88,0.88 0,0.49 -0.39,0.89 -0.88,0.89 -0.49,0 -0.89,-0.4 -0.89,-0.89 0,-0.48 0.4,-0.88 0.89,-0.88zm4.64 3.61c0,0.15 -0.12,0.28 -0.27,0.28l-6.01 0c-0.15,0 -0.27,-0.13 -0.27,-0.28l0 -0.16 1.09 -1.09 0.9 0.9c0.11,0.11 0.28,0.11 0.39,0l2.26 -2.26 1.91 1.91 0 0.7 0 0z"
      />
    </g>
  </svg>
    <ng-container [ngTemplateOutlet]="oneDriveTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #pdfTemplate>
  <div class="icon-wrapper">
    <svg
      [attr.height]="heightInPx + 'px'"
      [attr.width]="widthInPx + 'px'"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      version="1.1"
      viewBox="0 0 29.93 40.02"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="file-type-red"
          d="M5.21 0l13.38 0 11.34 11.82 0 22.99c0,2.88 -2.33,5.21 -5.2,5.21l-19.52 0c-2.88,0 -5.21,-2.33 -5.21,-5.21l0 -29.6c0,-2.88 2.33,-5.21 5.21,-5.21z"
        />

        <polygon
          class="file-type-white-semitransparent"
          points="18.58,0 18.58,11.73 29.93,11.73 "
        />

        <path
          class="file-type-white-nonzero"
          d="M5.79 29.86l0 -7.31 3.11 0c0.77,0 1.38,0.21 1.84,0.64 0.46,0.42 0.69,0.99 0.69,1.7 0,0.71 -0.23,1.28 -0.69,1.7 -0.46,0.43 -1.07,0.64 -1.84,0.64l-1.24 0 0 2.63 -1.87 0zm1.87 -4.22l1.03 0c0.28,0 0.5,-0.06 0.65,-0.2 0.15,-0.13 0.23,-0.31 0.23,-0.55 0,-0.24 -0.08,-0.42 -0.23,-0.55 -0.15,-0.14 -0.37,-0.2 -0.65,-0.2l-1.03 0 0 1.5zm4.54 4.22l0 -7.31 2.59 0c0.51,0 0.99,0.07 1.44,0.22 0.45,0.15 0.86,0.36 1.22,0.65 0.36,0.28 0.65,0.66 0.86,1.14 0.2,0.48 0.31,1.03 0.31,1.65 0,0.61 -0.11,1.16 -0.31,1.64 -0.21,0.48 -0.5,0.86 -0.86,1.14 -0.36,0.29 -0.77,0.5 -1.22,0.65 -0.45,0.15 -0.93,0.22 -1.44,0.22l-2.59 0zm1.83 -1.59l0.54 0c0.29,0 0.56,-0.03 0.81,-0.1 0.24,-0.07 0.47,-0.18 0.69,-0.33 0.21,-0.15 0.38,-0.36 0.5,-0.64 0.12,-0.28 0.18,-0.61 0.18,-0.99 0,-0.39 -0.06,-0.72 -0.18,-1 -0.12,-0.28 -0.29,-0.49 -0.5,-0.64 -0.22,-0.15 -0.45,-0.26 -0.69,-0.33 -0.25,-0.07 -0.52,-0.1 -0.81,-0.1l-0.54 0 0 4.13zm5.53 1.59l0 -7.31 5.2 0 0 1.59 -3.33 0 0 1.17 2.66 0 0 1.58 -2.66 0 0 2.97 -1.87 0z"
        />
      </g>
    </svg>
    <ng-container [ngTemplateOutlet]="oneDriveTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="signiTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #presentationTemplate>
  <div class="icon-wrapper">
    <svg
      [attr.height]="heightInPx + 'px'"
      [attr.width]="widthInPx + 'px'"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      version="1.1"
      viewBox="0 0 24.33 32.53"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="file-type-orange"
          d="M4.23 0l10.88 0 9.22 9.61 0 18.69c0,2.33 -1.9,4.23 -4.23,4.23l-15.87 0c-2.34,0 -4.23,-1.9 -4.23,-4.23l0 -24.07c0,-2.34 1.89,-4.23 4.23,-4.23z"
        />

        <polygon
          class="file-type-white-semitransparent"
          points="15.1,0 15.1,9.53 24.33,9.53 "
        />

        <path
          class="file-type-white-nonzero"
          d="M11.76 16.13c-2.44,0.12 -4.38,2.15 -4.35,4.62 0.04,2.46 2.04,4.47 4.5,4.5 2.48,0.04 4.51,-1.9 4.62,-4.34 0.01,-0.12 -0.09,-0.22 -0.21,-0.22l-4.13 0c-0.12,0 -0.22,-0.1 -0.22,-0.21l0 -4.14c0,-0.12 -0.09,-0.21 -0.21,-0.21zm1.1 -0.67l0 4.13c0,0.12 0.09,0.21 0.21,0.21l4.14 0c0.12,0 0.21,-0.1 0.21,-0.22 -0.11,-2.34 -1.99,-4.22 -4.34,-4.33 -0.12,-0.01 -0.22,0.09 -0.22,0.21z"
        />
      </g>
    </svg>
    <ng-container [ngTemplateOutlet]="oneDriveTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #spreadsheetTemplate>
  <div class="icon-wrapper">
    <svg
      [attr.height]="heightInPx + 'px'"
      [attr.width]="widthInPx + 'px'"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      version="1.1"
      viewBox="0 0 17.69 23.65"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="file-type-dark-green"
          d="M3.08 0l7.91 0 6.7 6.99 0 13.58c0,1.7 -1.38,3.08 -3.08,3.08l-11.53 0c-1.7,0 -3.08,-1.38 -3.08,-3.08l0 -17.49c0,-1.7 1.38,-3.08 3.08,-3.08z"
        />

        <polygon
          class="file-type-white-semitransparent"
          points="10.98,0 10.98,6.93 17.69,6.93 "
        />

        <path
          class="file-type-white"
          d="M5.21 17.35l3.14 0 0 1.19 -3.14 0 0 -1.19zm4.12 -6.08l3.15 0 0 1.18 -3.15 0 0 -1.18zm-4.12 0l3.14 0 0 1.18 -3.14 0 0 -1.18zm4.12 2l3.15 0 0 1.19 -3.15 0 0 -1.19zm-4.12 0l3.14 0 0 1.19 -3.14 0 0 -1.19zm4.12 2.08l3.15 0 0 1.19 -3.15 0 0 -1.19zm-4.12 0l3.14 0 0 1.19 -3.14 0 0 -1.19zm4.12 2l3.15 0 0 1.19 -3.15 0 0 -1.19z"
        />
      </g>
    </svg>
    <ng-container [ngTemplateOutlet]="oneDriveTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #unknownTemplate>
  <div class="icon-wrapper">
    <svg
      [attr.height]="heightInPx + 'px'"
      [attr.width]="widthInPx + 'px'"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      version="1.1"
      viewBox="0 0 24.33 32.53"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          class="file-type-black"
          d="M4.23 0l10.88 0 9.22 9.61 0 18.69c0,2.33 -1.9,4.23 -4.23,4.23l-15.87 0c-2.34,0 -4.23,-1.9 -4.23,-4.23l0 -24.07c0,-2.34 1.89,-4.23 4.23,-4.23z"
        />

        <polygon
          class="file-type-white-semitransparent"
          points="15.1,0 15.1,9.53 24.33,9.53 "
        />

        <path
          class="file-type-white-nonzero"
          d="M16.45 21.21l-8.57 0c-0.4,0 -0.72,0.32 -0.72,0.72 0,0.39 0.32,0.71 0.72,0.71l8.57 0c0.4,0 0.72,-0.32 0.72,-0.71 0,-0.4 -0.32,-0.72 -0.72,-0.72l0 0zm0 2.86l-8.57 0c-0.4,0 -0.72,0.32 -0.72,0.72 0,0.39 0.32,0.71 0.72,0.71l8.57 0c0.4,0 0.72,-0.32 0.72,-0.71 0,-0.4 -0.32,-0.72 -0.72,-0.72l0 0zm-4.36 -8.57l-4.21 0c-0.4,0 -0.72,0.31 -0.72,0.71 0,0.4 0.32,0.71 0.72,0.71l4.21 0c0.4,0 0.72,-0.31 0.72,-0.71 0,-0.4 -0.32,-0.71 -0.72,-0.71l0 0zm4.36 2.85l-8.57 0c-0.4,0 -0.72,0.32 -0.72,0.72 0,0.39 0.32,0.71 0.72,0.71l8.57 0c0.4,0 0.72,-0.32 0.72,-0.71 0,-0.4 -0.32,-0.72 -0.72,-0.72l0 0z"
        />
      </g>
    </svg>
  </div>
</ng-template>

<ng-template #oneDriveTemplate>
  <i *ngIf="oneDriveID" [ngbTooltip]="'files.one_drive_backed_up' | translate"
     class="mdi mdi-cloud"
     container="body"
     placement="top-left">
  </i>
</ng-template>

<ng-template #signiTemplate>
    <img *ngIf="signiRelated.enabled" [style.opacity]="signiRelated.sent ? '100%' : '50%'" [ngStyle]="oneDriveID ? {top: '1px', right: '23px'} : null" src="assets/images/integrations/signi.png" alt="Signi" class="signi-icon" [ngbTooltip]=" ('documents.using_signi' + (!signiRelated.sent ? '_not_sent' : '')) | translate" container="body" placement="bottom-left">
</ng-template>
