<!--<editor-fold desc="MODALS">-->
<ng-template #content id="fileEditModal" let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'files.action_edit' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                (ngSubmit)="onSubmit()"
                [formGroup]="fileEditForm"
                autocomplete="off"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label"></label>

                      <input
                        class="form-control"
                        formControlName="name"
                        name="name"
                        type="text"
                      >

                      <div *ngIf="submitted && f.name.errors">
                        <p
                          *ngIf="f.name.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }}</span>
                          <span>{{ 'accounts_categories.name' | translate }}</span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-left">
                    <button class="btn btn-success">
                      <span>{{ 'files.action_save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--</editor-fold desc="MODALS">-->

<ng-container *ngIf="files$ | async as files; else loadingTemplate">
    <div class="table-responsive">
      <ng-template #noFilesTemplate>
        <p class="text-center mt-3">{{ 'global.empty_widget_error' | translate }}</p>
      </ng-template>
      <ng-container>
        <table *ngIf="files.length; else noFilesTemplate" class="table table-centered mb-0">
          <tbody>
              <ng-container *ngIf="groupBy && groups">
                <ng-container *ngFor="let group of groups">
                    <tr>
                      <td (click)="collapseGroup(group)"
                          class="pl-0 text-truncate cursor-pointer text-dark font-weight-bold"
                          colspan="3">
                        <i [ngClass]="{'mdi-menu-down' : groupsCollapsed[group], 'mdi-menu-right': !groupsCollapsed[group]}"
                           class="mdi mdi-24px d-inline-block mr-2 menu-arrow-collapse"> </i>
                        <span> {{ group }} </span>
                      </td>
                    </tr>
                    <ng-container *ngFor="let file of files">
                      <tr *ngIf="file.group === group && groupsCollapsed[group]"
                          [ngTemplateOutletContext]="{file: file}"
                          [ngTemplateOutlet]="rowTemplate">
                      </tr>
                    </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!(groupBy && groups)">
                <tr *ngFor="let file of files"
                    [ngTemplateOutletContext]="{file: file, files: files}"
                    [ngTemplateOutlet]="rowTemplate">
                </tr>
              </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
    <div *ngIf="showSigniEnvelope && rowsChecked?.length > 0" class="row mt-3">
        <div class="col-12">
            <div class="form-group">
                <label class="control-label" for="signi-main-file">
                    <span>{{ 'files.select_signi_file' | translate }}</span>
                </label>
                <ng-select [items]="signiSelectableFiles"
                           id="signi-main-file"
                           bindLabel="name"
                           bindValue="file_ID"
                           [(ngModel)]="signiMainFile"
                           [multiple]="false"
                           [closeOnSelect]="true"
                           [searchable]="true"
                           [clearable]="false"
                           [placeholder]="'files.select_signi_file' | translate">
                </ng-select>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-between">
                <span class="ml-1">{{ 'files.selected_count' | translate }}: {{ rowsChecked.length }}</span>
                <div>
                    <button (click)="rowsChecked = []" class="btn btn-danger mr-1"
                            title="{{ 'global.action_cancel' | translate }}">
                        {{ 'global.action_cancel' | translate }}
                    </button>
                    <button (click)="sendSigniEnvelope()" class="btn btn-success"
                            type="submit" submitButton
                            title="{{ 'files.action_send_signi' | translate }}">
                        {{ 'files.action_send_signi' | translate }}
                    </button>
                </div>
        </div>
    </div>
</ng-container>

<ng-template #rowTemplate let-file='file' let-files='files'>
    <td *ngIf="showSigniEnvelope">
        <div class="form-check">
            <input (click)="rowChecked($event, file, files)" [disabled]="file.signature_service !== 'SIGNI' || file.signi_contract_id" [checked]="rowsChecked.includes(file.file_ID)" aria-label="..."
                   class="form-check-input position-static" type="checkbox">
        </div>
    </td>
  <td [ngClass]="{'pl-3': groupBy && groups}"
      class="pl-0 text-truncate">
    <app-file-type [ext]="file.extension" [oneDriveID]="file.one_drive_ID" [signiRelated]="{enabled: file.signature_service === 'SIGNI', sent: file.signi_contract_id !== null}" class="force-left"></app-file-type>
    {{ file.name }}
  </td>

  <ng-container *ngIf="additionalColumnKeys?.length">
    <td *ngFor="let key of additionalColumnKeys">{{ file[key] }}</td>
  </ng-container>

  <td>
    <span class="text-muted">
      <small>{{ file.created_at | date:'dd.MM.yyyy' }}</small>
    </span>
  </td>

  <td class="pr-0 text-nowrap d-flex justify-content-end align-items-baseline">
      <ng-container *ngIf="!file.signi_contract_id">
    <button *ngIf="allowedDocumentSignature === true && file.signature_state === 'TO_BE_SIGNED'" [routerLink]="['/', 'file-detail', entityType, entityID ,'file', file.hash, 'detail']" class="btn btn-link btn-icon btn-sm mr-1" title="{{ 'files.has_to_be_signed' | translate }}" type="button">
      <i class="mdi mdi-signature-freehand text-danger mdi-24px"></i>
    </button>
      </ng-container>

    <button (click)="openDocumentModal(file.hash)" *ngIf="file && !showFileOnNewPage" class="btn btn-link btn-icon btn-sm mr-1" title="{{ 'employees.action_view' | translate }}" type="button">
      <i class="mdi mdi-eye-outline text-muted mdi-24px"></i>
    </button>
    <button *ngIf="file && showFileOnNewPage" [routerLink]="['/', 'file-detail', entityType, entityID ,'file', file.hash, 'detail']" class="btn btn-link btn-icon btn-sm mr-1" title="{{ 'employees.action_view' | translate }}" type="button">
      <i class="mdi mdi-eye-outline text-muted mdi-24px"></i>
    </button>

    <button (click)="openEditModal(content, file)" *ngIf="allowDelete" class="btn btn-link btn-icon waves-effect p-0 mr-2" type="button">
      <i class="mdi mdi-pencil-outline mdi-24px text-muted"></i>
    </button>

      <a href="{{file.download_url}}" target="_blank" download="{{file.name}}" type="button" class="btn btn-link btn-icon pl-0 pr-0 mr-2">
          <i class="mdi mdi-tray-arrow-down text-muted mdi-24px"></i>
      </a>

    <a (click)="documentService.openDocumentDetailModal(file.hash)" *ngIf="file.extension === 'pdf' && f.hash" class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0 mr-2">
      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
    </a>

    <button (click)="deleteFile(file.file_ID, file.document_ID)" *ngIf="allowDelete" class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0" type="button">
      <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
    </button>
  </td>
</ng-template>

<ng-template #loadingTemplate>
        <div class="item mt-3">
            <ngx-skeleton-loader [theme]="{
        'margin': '10px 0'
      }" appearance="line" count="5"></ngx-skeleton-loader>
        </div>
</ng-template>
